import homeImg from "../../assets/images/hero-carousel-img.png";
// import homeImg2 from "../../assets/images/homeImg2.png";
import homeImg2 from "../../assets/images/hero-new-img-2.jpg";
import customNextButton from "../../assets/icons/angle-right.png";
import customPrevButton from "../../assets/icons/angle-left.png";
import homeRainbowBg from "../../assets/images/homeRainbowBg.png";
import mobileHomeRainbowBg from "../../assets/images/mobileHomeRainbowBg.png";
import styled from "styled-components";
import { HowItWorks2 } from "./HowItWorks2";
import { SingleBottle } from "../../components/SingleBottle";
import { Button } from "../../components/Button";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useEffect, useRef, useState } from "react";
import { BlackButton } from "../../components/black-button";
import axios from "axios";
import { environment } from "../../environments/environment";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import { Stack, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import joinCampaignBg from "../../assets/images/join-campaign.svg";
import moment from "moment";
import MailchimpPopUp from "../../components/MailchimpPopUp";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CookieConsent from "../../components/CookieConsent";

interface CampaignImagesProps {
  campaign_uuid: string;
  media_url: string;
}

export const Homepage = () => {
  const initialized = useRef(false);
  const [activeHeadingText, setActiveHeadingText] = useState(0);
  const navigate = useNavigate();
  const swiperRef = useRef();
  const headingTexts: any[] = [
    {
      h2: "As more people back a campaign, prices decrease",
      p: "With increased support, campaigns benefit from economies of scale leading to lower production costs and prices.",
      img: homeImg,
    },
    {
      h2: "Increase Your Purchasing Power by Joining Our Community",
      img: homeImg2,
    },
  ];

  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [ordersData, setOrdersData] = useState<{ [key: string]: number }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [campaignImages, setCampaignImages] = useState<
    CampaignImagesProps[] | null
  >(null);
  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("cookie_consent") === "accepted") {
      setIsCookieConsentVisible(false);
    } else {
      setIsCookieConsentVisible(true);
    }
  }, []);

  useEffect(() => {
    fetchCampaigns(currentPage);
  }, [currentPage]);

  const fetchCampaigns = async (page: number) => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch?page=${page}`
      );

      const campaignImages = response.data.data.campaign_images;

      const extractedImages = Object.entries(campaignImages)
        .filter(
          ([campaign_uuid, images]) =>
            (images as { campaign_uuid: string; media_url: string }[]).length >
            0
        )
        .map(([campaign_uuid, images]) => {
          const firstImage = (
            images as { campaign_uuid: string; media_url: string }[]
          )[0];
          return {
            campaign_uuid: firstImage.campaign_uuid,
            media_url: firstImage.media_url,
          };
        });

      // console.log(extractedImages);

      setCampaignImages(extractedImages);

      const sortedCampaigns = response.data.data?.campaigns?.rows.sort(
        (a: any, b: any) => a.sort_id - b.sort_id
      );

      // console.log(sortedCampaigns);
      setCampaigns(sortedCampaigns ?? null);

      // setCampaigns(response.data.data?.campaigns?.rows ?? null);
      setOrdersData(response.data.data?.current_orders ?? {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <main
        style={{ backgroundImage: `url(${homeRainbowBg})` }}
        className="bg-cover hidden lg:grid  desktop_home bg-no-repeat rounded-2xl mt-10 nottall:!mt-0 tall:mt-[100px] w-[90%] mx-auto  items-center gap-[70px] lg:grid-cols-[45%,1fr] z-0"
      >
        <div className="about_layout_content">
          <h2 className="web_heading bar_above">
            {headingTexts[activeHeadingText]?.h2}
          </h2>
          <p className="web_paragraph_1 mt-2">
            {headingTexts[activeHeadingText]?.p}
          </p>
          <br />

          <BlackButton
            onClick={() => navigate("/campaigns")}
            className="!bg-[#78D1E7] !text-black"
            text="View Campaigns"
          ></BlackButton>
        </div>

        {/* <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={6000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          className="home_carousel"
          onChange={(index) => setActiveHeadingText(index)}
        >
          <div className="w-[80%] mx-auto relative translate-y-[20%] !z-30">
            <img
              src={homeImg}
              alt="Man holding Water Bottle"
              style={{ zIndex: 999999 }}
            />
          </div>
          <div className="w-[80%] mx-auto relative -z-20">
            <img src={homeImg2} alt="Man holding Water Bottle" />
          </div>
        </Carousel> */}
        <div className="w-[530px] hero-carousel-container">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            observer={true}
            pagination={false}
            speed={500}
            autoplay={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper hero-carousel mobile-hero-carousel"
          >
            <SwiperSlide>
              <img
                src={homeImg}
                className="w-full h-auto relative overflow-y-visible  translate-y-12"
                alt="Man holding Water Bottle"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={homeImg2}
                className="w-full h-auto translate-y-3"
                alt="Man holding Water Bottle"
              />
            </SwiperSlide>
            <div className="swiper-button-next">
              <img src={customNextButton} alt="Next" />
            </div>
            <div className="swiper-button-prev">
              <img src={customPrevButton} alt="Previous" />
            </div>
          </Swiper>
        </div>
      </main>

      <main
        style={{ backgroundImage: `url(${mobileHomeRainbowBg})` }}
        className="bg-cover mobile_home lg:hidden bg-no-repeat rounded-2xl mt-10 nottall:!mt-0 tall:mt-[100px] grid items-center gap-[70px]"
      >
        {/* <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={6000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          className="home_carousel"
          onChange={(index) => setActiveHeadingText(index)}
        >
          <div className="w-[70%] mx-auto h-full flex justify-center items-center relative translate-y-[15%] shadow-md">
            <img src={homeImg} />
          </div>
          <div className="w-[70%] mx-auto h-full flex justify-center items-center">
            <img src={homeImg2} />
          </div>
        </Carousel> */}
        <div className="w-[300px] mx-auto hero-carousel-container">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            observer={true}
            pagination={false}
            speed={600}
            autoplay={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper hero-carousel"
          >
            <SwiperSlide>
              <div className="flex items-center justify">
                <img
                  src={homeImg}
                  className="w-full h-auto relative overflow-visible  translate-y-8"
                  alt="Man holding Water Bottle"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex items-center justify ">
                <img
                  src={homeImg2}
                  className="w-full h-auto mt-1.5 "
                  alt="Man holding Water Bottle"
                />
              </div>
            </SwiperSlide>
            <div className="w-screen">
              <div className="swiper-button-next ">
                <img src={customNextButton} alt="Next" />
              </div>
              <div className="swiper-button-prev">
                <img src={customPrevButton} alt="Previous" />
              </div>
            </div>
          </Swiper>
        </div>
      </main>

      <div className="lg:hidden web_padding_y web_padding_x bg-no-repeat bg-right ">
        <div className="">
          <h2 className="web_heading bar_above">
            {headingTexts[activeHeadingText]?.h2}
          </h2>
          <p className="web_paragraph_1 mt-2">
            {headingTexts[activeHeadingText]?.p}
          </p>
          <br />

          <BlackButton
            onClick={() => navigate("/campaigns")}
            text="View Campaigns"
          ></BlackButton>
        </div>
      </div>

      <Wrapper
        className="web_padding_y web_padding_x bg-no-repeat bg-right relative overflow-x-hidden"
        // style={{
        //   backgroundImage: `url(${joinCampaignBg})`,
        // }}
      >
        <h2 className="web_heading">Join a Campaign</h2>
        {campaigns?.length && (
          <img
            src={joinCampaignBg}
            alt=""
            className="absolute right-0 translate-x-[100px] top-[10%] h-[650px] hidden md:block [z-index:-1]"
          />
        )}
        {campaigns && (
          <>
            <div className="grid gap-6 lg:grid-cols-3">
              {campaigns?.map((campaign: ICampaignAdmin, index) => {
                const campaignImageUrl = campaignImages?.find(
                  (img) => img.campaign_uuid === campaign.uuid
                )?.media_url;

                return (
                  <SingleBottle
                    key={campaign.uuid}
                    campaignImage={campaignImageUrl!}
                    campaign={campaign}
                    currentOrders={ordersData[campaign.uuid]}
                  />
                );
              })}
            </div>
          </>
        )}
        {campaigns?.length === 0 && (
          <>
            <div className="border border-dashed text-center border-gray-400 mt-4 mx-auto max-w-[450px] rounded-lg px-10 py-20">
              No campaigns found
            </div>
          </>
        )}
        {!campaigns && (
          <>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <Skeleton variant="rounded" width={400} height={200} />
                <Skeleton variant="rounded" width={400} height={200} />
                <Skeleton variant="rounded" width={400} height={200} />
              </Stack>
            </Stack>
          </>
        )}
        <Button
          className="btn"
          name="view more"
          onClick={() => navigate("/campaigns")}
          icon={<HiOutlineArrowNarrowRight />}
        />
      </Wrapper>
      {isCookieConsentVisible && (
        <CookieConsent onClose={() => setIsCookieConsentVisible(false)} />
      )}
      {!isCookieConsentVisible && <MailchimpPopUp />}
      {/* {isCookieConsentVisible ? <CookieConsent /> : <MailchimpPopUp />} */}
      <HowItWorks2 />
    </>
  );
};

const Wrapper = styled.section`
  background-size: 15%;
  h2 {
    margin-bottom: 3.375rem;
  }
  /* .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
  } */
  .btn {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 4.7rem;
    /*  */
  }
`;
