import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsXLg, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { environment } from "../../environments/environment";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

interface FullscreenViewProps {
  images: string[];
  currentIndex: number;
  onClose: () => void;
  onPrev: () => void;
  onNext: () => void;
}

// FIXME: SORT ID TO ARRANGE THE IMAGES CORRECTLY
interface GalleryImage {
  uuid: string;
  media_url: string;
}

const FullscreenView: React.FC<FullscreenViewProps> = ({
  images,
  currentIndex,
  onClose,
  onPrev,
  onNext,
}) => {
  const cloudinaryImageUrl = images[currentIndex].includes("cloudinary.com")
    ? images[currentIndex].replace(
        "/upload/w_900,h_900,c_fit,f_auto/",
        "/upload/w_1920,h_1080,c_fit,ar_16:9,f_auto,q_auto:best,dpr_auto,c_lpad/"
      )
    : images[currentIndex];

  const isVideo = ReactPlayer.canPlay(cloudinaryImageUrl);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="relative w-full h-full max-w-7xl max-h-[95vh] flex items-center justify-center">
          {isVideo ? (
            <motion.div
              className="w-full h-full"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <ReactPlayer
                url={cloudinaryImageUrl}
                playing
                loop
                muted
                controls
                width="100%"
                height="100%"
                style={{ objectFit: "contain" }}
              />
            </motion.div>
          ) : (
            <motion.img
              key={currentIndex}
              src={cloudinaryImageUrl}
              alt={`Fullscreen view of image ${currentIndex + 1}`}
              className="w-full h-full object-contain"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </div>
      </div>

      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white z-10"
        aria-label="Close fullscreen view"
      >
        <BsXLg size={24} />
      </button>
      <button
        onClick={onPrev}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white z-10"
        aria-label="Previous image"
      >
        <BsChevronLeft size={24} />
      </button>
      <button
        onClick={onNext}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white z-10"
        aria-label="Next image"
      >
        <BsChevronRight size={24} />
      </button>
    </motion.div>
  );
};

export const Gallery = () => {
  const [fullscreenIndex, setFullscreenIndex] = useState<number | null>(null);
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);

  const openFullscreen = (index: number) => setFullscreenIndex(index);
  const closeFullscreen = () => setFullscreenIndex(null);
  const showPrevImage = () =>
    setFullscreenIndex((prev) =>
      prev !== null && prev > 0 ? prev - 1 : galleryImages.length - 1
    );
  const showNextImage = () =>
    setFullscreenIndex((prev) =>
      prev !== null && prev < galleryImages.length - 1 ? prev + 1 : 0
    );

  const fetchGalleryImages = async () => {
    try {
      const response = await axios.get(`${environment.baseUrl}/gallery`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        // console.log(response.data.data);
        const apiResponse = response.data.data.images || {};

        // Extract Image URLs and UUIDs
        // const extractedItems = apiResponse.map((item: any) => ({
        //   uuid: item.uuid,
        //   image_url: item.image_url,
        // }));

        const extractedItems = apiResponse.map((item: any) => {
          const cloudinaryImageUrl = item.media_url.includes("cloudinary.com")
            ? item.media_url.replace(
                "/upload/",
                "/upload/w_900,h_900,c_fit,f_auto/"
              )
            : item.media_url;
          return {
            uuid: item.uuid,
            media_url: cloudinaryImageUrl,
          };
        });

        // console.log("Extracted Items:", extractedItems);
        setGalleryImages(extractedItems);
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred");
    }
  };

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  return (
    <main className="web_padding_x web_padding_y ">
      <h2 className="web_heading bar_above text-center">Gallery</h2>
      <motion.div
        className="grid grid-cols-1 mt-6 gap-8 md:grid-cols-2 lg:grid-cols-3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {galleryImages.map((img, index) => (
          <motion.div
            key={index}
            className="relative overflow-hidden aspect-square"
            whileHover={{ scale: 1.02 }}
            // transition={{ type: "spring", stiffness: 300 }}
            transition={{ duration: 0.5, type: "tween", ease: "easeInOut" }}
            onClick={() => openFullscreen(index)}
          >
            {img.media_url.slice(-3) === "mp4" ? (
              <motion.video
                src={img.media_url}
                autoPlay
                loop
                muted
                playsInline
                className="w-full h-full object-cover cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              />
            ) : (
              <motion.img
                src={img.media_url}
                alt={`Gallery Image ${index + 1}`}
                className="w-full h-full object-cover cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              />
            )}
          </motion.div>
        ))}

        <AnimatePresence>
          {fullscreenIndex !== null && (
            <FullscreenView
              images={galleryImages.map((img) => img.media_url)}
              currentIndex={fullscreenIndex}
              onClose={closeFullscreen}
              onPrev={showPrevImage}
              onNext={showNextImage}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </main>
  );
};
