import React, { useState } from "react";

export const ItemCountInput = ({ value, onValueChange }: any) => {
  const handleValueChange = (increment: boolean) => {
    if (increment) {
      onValueChange(value + 1);
    } else {
      const newValue = value - 1;
      if (newValue > 0) {
        onValueChange(newValue);
      }
    }
  };

  return (
    <>
      <div className="relative">
        <button
          onClick={() => handleValueChange(false)}
          type="button"
          className="absolute center_y hover:scale-[1.2] text-lg left-3"
        >
          -
        </button>
        <button
          onClick={() => handleValueChange(true)}
          type="button"
          className="absolute center_y hover:scale-[1.2] text-lg right-3"
        >
          +
        </button>
        <input
          type="number"
          value={value}
          onChange={(e) => {
            onValueChange(Number(e.target.value));
            if (Number(e.target.value) < 1) {
              onValueChange(1);
            }
          }}
          className="border hide_arrows outline-none text-center py-2 px-7 w-full border-solid border-[#707070] rounded-[18px] h-9"
        />
      </div>
    </>
  );
};
