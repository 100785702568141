import {
  CLOSE_SIDEBAR,
  CLOSE_SIDEBAR_ADMIN,
  TOGGLE_SIDEBAR,
  TOGGLE_SIDEBAR_ADMIN,
} from "./actions";

export interface ISharedState {
  navOpen: boolean;
  navOpenAdmin: boolean;
}

const initialState: ISharedState = {
  navOpen: false,
  navOpenAdmin: false,
};

export const sharedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        navOpen: !state.navOpen,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        navOpen: false,
      };
    case TOGGLE_SIDEBAR_ADMIN:
      return {
        ...state,
        navOpenAdmin: !state.navOpenAdmin,
      };
    case CLOSE_SIDEBAR_ADMIN:
      return {
        ...state,
        navOpenAdmin: false,
      };
    default:
      return state;
  }
};
