import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiAlertCircle, FiMail, FiUser } from "react-icons/fi";

interface JoinCommunityFormProps {
  first_name: string;
  last_name: string;
  email: string;
}

interface FormProps {
  status: string;
  message: string;
  onValidated: (values: {
    EMAIL: string;
    MERGE1: string;
    MERGE2: string;
  }) => void;
}

const JoinCommunityForm = () => {
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const initialValues: JoinCommunityFormProps = {
    email: "",
    first_name: "",
    last_name: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
  });

  // const onSubmit = (values: JoinCommunityFormProps) => {
  //   // console.log("Form data", values);
  //   onValidated({
  //     EMAIL: values.email,
  //     MERGE1: values.first_name,
  //     MERGE2: values.last_name,
  //   });
  // };

  const handleSubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const postUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${
      process.env.REACT_APP_MAILCHIMP_U
    }&id=${process.env.REACT_APP_MAILCHIMP_ID}&EMAIL=${encodeURIComponent(
      email
    )}&MERGE1=${encodeURIComponent(firstName)}&MERGE2=${encodeURIComponent(
      lastName
    )}`;
    console.log(postUrl);

    try {
      const response = await fetch(postUrl, {
        method: "POST",
        mode: "no-cors", // This mode allows the request but without returning a response
      });

      // Since "no-cors" mode doesn’t give us any response status, assume success.
      // setStatus("success");

      if (response.statusText === "") {
        setStatus("success");
        setMessage(
          "Thank you for subscribing! Please check your email for updates from us."
        );
      } else {
        setStatus("error");
        setMessage("An error occurred, please try again later.");
      }
    } catch (error) {
      setStatus("error");
      setMessage("An error occurred, please try again later.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-12 p-8 bg-gradient-to-br from-white to-gray-100 rounded-3xl shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-[#10152B] mb-4">
            JOIN OUR COMMUNITY
          </h2>
          <p className="text-gray-600 mb-6">
            Sign up to be the first to hear about our limited-edition collection
            and exclusive products when they go live in December.
          </p>
          {/* {status === "sending" && (
            <div className="bg-blue-100 text-blue-700 p-3 rounded-lg mb-4">
              Sending...
            </div>
          )}
          {status === "error" && (
            <div
              className="bg-red-100 text-red-700 p-3 rounded-lg mb-4"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )} */}
          {status === "success" && (
            <div
              className={`${
                status === "success"
                  ? "bg-green-100 text-green-700"
                  : "bg-red-100 text-red-700"
              } p-3 rounded-lg mb-4`}
            >
              {message}
            </div>
          )}
        </div>
        <div>
          <form className="space-y-4">
            <div className="relative">
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                placeholder="First Name"
              />
              <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="relative">
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                placeholder="Last Name"
              />
              <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
                placeholder="Email"
              />
              <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>

            <button
              onClick={(e) => handleSubscribe(e)}
              className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunityForm;
