import styled from "styled-components";
import { SingleBottle } from "../../components/SingleBottle";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import blackAngleUp from "../../assets/icons/angle-up.png";
import blackAngleDown from "../../assets/icons/angle-down.png";
import { OutlineButton } from "../../components/outline-button";
import { FiSearch } from "react-icons/fi";
import Slider from "@mui/material/Slider";
import { useEffect, useRef, useState } from "react";
import { environment } from "../../environments/environment";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import { Checkbox, Pagination, Skeleton, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../api/api";

const sortDropdown = [
  { name: "NONE", value: null },
  { name: "BEST SELLER", value: "best_seller" },
  { name: "BEST MATCH", value: "best_match" },
  { name: "PRICE LOW", value: "price_low" },
  { name: "PRICE HIGH", value: "price_high" },
  { name: "ALPHABETICALLY (A-Z)", value: "alphabetically_a" },
  { name: "ALPHABETICALLY (Z-A)", value: "alphabetically_z" },
];

interface CampaignImagesProps {
  campaign_uuid: string;
  media_url: string;
}

export const Campaigns = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [priceRange, setValue] = useState<number[]>([0, 250]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
  const [sort, setSort] = useState<string | null>(null);

  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [campaignImages, setCampaignImages] = useState<
    CampaignImagesProps[] | null
  >(null);
  const [totalPages, setTotalPages] = useState(1);
  const [ordersData, setOrdersData] = useState<{ [key: string]: number }>({});

  const [typesList, setTypesList] = useState<
    { name: string; short_name: string }[]
  >([{ name: "Footwear", short_name: "footwear" }]);

  const [sizeList, setSizeList] = useState<
    { name: string; short_name: string }[]
  >([
    { name: "5", short_name: "5" },
    { name: "5.5", short_name: "5.5" },
    { name: "6", short_name: "6" },
    { name: "6.5", short_name: "6.5" },
    { name: "7", short_name: "7" },
    { name: "7.5", short_name: "7.5" },
    { name: "8", short_name: "8" },
    { name: "8.5", short_name: "8.5" },
    { name: "9", short_name: "9" },
    { name: "10", short_name: "10" },
    { name: "11", short_name: "11" },
    { name: "12", short_name: "12" },
  ]);

  const [colorsList, setColorsList] = useState<
    { name: string; short_name: string }[]
  >([
    { name: "Black", short_name: "black" },
    { name: "Brown", short_name: "brown" },
    { name: "White", short_name: "white" },
    { name: "Green", short_name: "green" },
    { name: "Pink", short_name: "pink" },
    { name: "Red", short_name: "red" },
  ]);

  const [sortByList, setSortByListList] = useState<
    { name: string; short_name: string }[]
  >([
    { name: "Featured", short_name: "featured" },
    { name: "Best Selling", short_name: "best_selling" },
    { name: "Alphabetically A-Z", short_name: "alphabetically_a_z" },
    { name: "Alphabetically Z-A", short_name: "alphabetically_z_a" },
    { name: "Price Low-High", short_name: "price_low_high" },
    { name: "Price High-Low", short_name: "price_high_low" },
    { name: "Date Old-New", short_name: "date_old_new" },
    { name: "Date New-Old", short_name: "date_new_old" },
  ]);

  const [categoriesList, setCategoriesList] = useState<
    { name: string; short_name: string }[]
  >([]);

  const fetchCategoriesList = async () => {
    try {
      const response = await api.get(`${environment.baseUrl}/admin/categories`);

      if (response.data.code == 200) {
        setCategoriesList(response.data.data.all_categories);
      }
    } catch (error) {
      navigate("/campaigns");
    }
  };

  useEffect(() => {
    fetchCategoriesList();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const sort = params.get("sort");

    if (page) {
      setCurrentPage(Number(page));
    }

    if (sort) {
      setSort(sort);
    }

    fetchCampaigns();
  }, [currentPage, categoriesSelected, sort]);

  const fetchCampaigns = async () => {
    const params: any = { page: currentPage };

    if (categoriesSelected[0]) {
      params.category = categoriesSelected[0].toLowerCase();
    }
    if (sort) {
      params.sort = sort;
    }
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/list`,
        {
          params,
        }
      );

      const campaignImages = response.data.data.campaign_images;

      const extractedImages = Object.entries(campaignImages)
        .filter(
          ([campaign_uuid, images]) =>
            (images as { campaign_uuid: string; media_url: string }[]).length >
            0
        )
        .map(([campaign_uuid, images]) => {
          const firstImage = (
            images as { campaign_uuid: string; media_url: string }[]
          )[0];

          return {
            campaign_uuid: firstImage.campaign_uuid,
            media_url: firstImage.media_url,
          };
        });

      setCampaignImages(extractedImages);

      setCampaigns(response.data.data?.campaigns?.rows ?? null);
      setOrdersData(response.data.data?.current_orders ?? {});
      setTotalPages(response.data.data?.campaigns.total_pages);
    } catch (error) {}
  };

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setValue(newValue as number[]);

    // Update URL parameters
    const params = new URLSearchParams(location.search);
    params.set("minprice", (newValue as number[])[0].toString());
    params.set("maxprice", (newValue as number[])[1].toString());
    navigate(`?${params}`);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    // Scroll to the top of the body
    window.scrollTo({ top: 0, behavior: "smooth" });
    const params = new URLSearchParams(location.search);
    params.set("page", page.toString());
    navigate(`?${params}`);
  };

  return (
    <Wrapper className="web_padding_y web_padding_x">
      <h2 className="web_heading text-left md:text-center">CAMPAIGNS</h2>
      <div className="flex cont mt-5 md:mt-10">
        {/* <div className="filter">
          <Menu
            className="campaign_brand_menu"
            menuButton={
              <MenuButton className="w-full sm:w-fit">
                <OutlineButton
                  text="TYPE"
                  className="w-full sm:w-fit justify-center"
                  icon={<img className="w-4" src={blackAngleDown} />}
                />
              </MenuButton>
            }
            direction="bottom"
            align="center"
            offsetY={24}
            arrow={true}
          >
            <div className="font-medium rounded mt-2 grid text-lg py-3 bg-white max-h-[270px] overflow-y-auto">
              {typesList.map((type, index) => (
                <MenuItem
                  key={index}
                  onClick={() => false}
                  className="flex items-center font-semibold !px-2 justify-between"
                >
                  <Checkbox
                    aria-label={type.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const catArr = [...categoriesSelected];
                      if (e.target.checked) {
                        catArr.push(type.short_name);
                        setCategoriesSelected(catArr);
                      } else {
                        const updatedCategories = catArr.filter(
                          (cat) => cat !== type.short_name
                        );
                        setCategoriesSelected(updatedCategories);
                      }
                    }}
                  />
                  <label
                    htmlFor="item-1"
                    className="flex-1 text-base sm:text-xl leading-5"
                  >
                    {type.name}
                  </label>
                  {/* <span className="text-xl sm:text-2xl leading-6">
                    120
                    <span className="leading-5 font-normal text-[#d4d4d4]">
                      +
                    </span>
                  </span> 
                </MenuItem>
              ))}
            </div>
            <div className="mt-4 h-[2px] bg-[#d4d4d4]"></div>
            <button className="block mt-1 mx-auto w-fit outline-none underline text-[#d4d4d4] text-lg font-semibold">
              Clear
            </button>
          </Menu>
          <Menu
            className="campaign_brand_menu"
            menuButton={
              <MenuButton className="w-full sm:w-fit">
                <OutlineButton
                  text="SIZE"
                  className="w-full sm:w-fit justify-center"
                  icon={<img className="w-4" src={blackAngleDown} />}
                />
              </MenuButton>
            }
            direction="bottom"
            align="center"
            offsetY={24}
            arrow={true}
          >
            <div className="font-medium rounded mt-2 grid text-lg py-3 bg-white max-h-[270px] overflow-y-auto">
              {sizeList.map((category, index) => (
                <MenuItem
                  key={index}
                  onClick={() => false}
                  className="flex items-center font-semibold !px-2 justify-between"
                >
                  <Checkbox
                    aria-label={category.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const catArr = [...categoriesSelected];
                      if (e.target.checked) {
                        catArr.push(category.short_name);
                        setCategoriesSelected(catArr);
                      } else {
                        const updatedCategories = catArr.filter(
                          (cat) => cat !== category.short_name
                        );
                        setCategoriesSelected(updatedCategories);
                      }
                    }}
                  />
                  <label
                    htmlFor="item-1"
                    className="flex-1 text-base sm:text-xl leading-5"
                  >
                    {category.name}
                  </label>
                  {/* <span className="text-xl sm:text-2xl leading-6">
                    120
                    <span className="leading-5 font-normal text-[#d4d4d4]">
                      +
                    </span>
                  </span> 
                </MenuItem>
              ))}
            </div>
            <div className="mt-4 h-[2px] bg-[#d4d4d4]"></div>
            <button className="block mt-1 mx-auto w-fit outline-none underline text-[#d4d4d4] text-lg font-semibold">
              Clear
            </button>
          </Menu>
          <Menu
            className="campaign_brand_menu"
            menuButton={
              <MenuButton className="w-full sm:w-fit">
                <OutlineButton
                  text="COLORS"
                  className="w-full sm:w-fit justify-center"
                  icon={<img className="w-4" src={blackAngleDown} />}
                />
              </MenuButton>
            }
            direction="bottom"
            align="center"
            offsetY={24}
            arrow={true}
          >
            <div className="font-medium rounded mt-2 grid text-lg py-3 bg-white max-h-[270px] overflow-y-auto">
              {colorsList.map((category, index) => (
                <MenuItem
                  key={index}
                  onClick={() => false}
                  className="flex items-center font-semibold !px-2 justify-between"
                >
                  <Checkbox
                    aria-label={category.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const catArr = [...categoriesSelected];
                      if (e.target.checked) {
                        catArr.push(category.short_name);
                        setCategoriesSelected(catArr);
                      } else {
                        const updatedCategories = catArr.filter(
                          (cat) => cat !== category.short_name
                        );
                        setCategoriesSelected(updatedCategories);
                      }
                    }}
                  />
                  <label
                    htmlFor="item-1"
                    className="flex-1 text-base sm:text-xl leading-5"
                  >
                    {category.name}
                  </label>
                  {/* <span className="text-xl sm:text-2xl leading-6">
                    120
                    <span className="leading-5 font-normal text-[#d4d4d4]">
                      +
                    </span>
                  </span> 
                </MenuItem>
              ))}
            </div>
            <div className="mt-4 h-[2px] bg-[#d4d4d4]"></div>
            <button className="block mt-1 mx-auto w-fit outline-none underline text-[#d4d4d4] text-lg font-semibold">
              Clear
            </button>
          </Menu>
          <Menu
            className="campaign_brand_menu"
            menuButton={
              <MenuButton className="w-full sm:w-fit">
                <OutlineButton
                  text="SORT BY"
                  className="w-full sm:w-fit justify-center"
                  icon={<img className="w-4" src={blackAngleDown} />}
                />
              </MenuButton>
            }
            direction="bottom"
            align="center"
            offsetY={24}
            arrow={true}
          >
            <div className="font-medium rounded mt-2 grid text-lg py-3 bg-white max-h-[270px] overflow-y-auto">
              {sortByList.map((category, index) => (
                <MenuItem
                  key={index}
                  onClick={() => false}
                  className="flex items-center font-semibold !px-2 justify-between"
                >
                  <Checkbox
                    aria-label={category.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const catArr = [...categoriesSelected];
                      if (e.target.checked) {
                        catArr.push(category.short_name);
                        setCategoriesSelected(catArr);
                      } else {
                        const updatedCategories = catArr.filter(
                          (cat) => cat !== category.short_name
                        );
                        setCategoriesSelected(updatedCategories);
                      }
                    }}
                  />
                  <label
                    htmlFor="item-1"
                    className="flex-1 text-base sm:text-xl leading-5"
                  >
                    {category.name}
                  </label>
                  {/* <span className="text-xl sm:text-2xl leading-6">
                    120
                    <span className="leading-5 font-normal text-[#d4d4d4]">
                      +
                    </span>
                  </span> 
                </MenuItem>
              ))}
            </div>
            <div className="mt-4 h-[2px] bg-[#d4d4d4]"></div>
            <button className="block mt-1 mx-auto w-fit outline-none underline text-[#d4d4d4] text-lg font-semibold">
              Clear
            </button>
          </Menu>
        </div> */}

        <Menu
          className="campaign_sort"
          menuButton={
            <MenuButton>
              <div className="flex items-center gap-2 group">
                <p className="font-bold flex gap-4">
                  <span className="sort">SORT BY</span>
                  <span className="font-normal group-hover:scale-[1.01] duration-700">
                    {sortDropdown.find((x) => x.value === sort)?.name}
                  </span>
                </p>
                <img
                  src={blackAngleUp}
                  className="w-4 group-hover:scale-[1.3] duration-700"
                />
              </div>
            </MenuButton>
          }
          direction="bottom"
          align="center"
          offsetY={24}
          arrow={true}
        >
          <div className="font-medium rounded grid  text-lg py-3 bg-white">
            {sortDropdown.map((item, index) => (
              <MenuItem
                className={`${sort === item.value ? "active_sort" : ""}`}
                onClick={() => {
                  setSort(item.value);
                  const params = new URLSearchParams(location.search);
                  if (item.value) {
                    params.set("sort", item.value);
                  } else {
                    params.delete("sort");
                  }
                  navigate(`?${params}`);
                }}
                key={index}
              >
                {item.name}
              </MenuItem>
            ))}
          </div>
        </Menu>

        <div className="flex justify-center mt-4">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
      {campaigns && (
        <>
          <div className="grid gap-6 sm:[grid-template-columns:repeat(auto-fill,minmax(300px,_1fr))] lg:grid-cols-3">
            {campaigns?.map((campaign: ICampaignAdmin) => {
              const campaignImageUrl = campaignImages?.find(
                (img) => img.campaign_uuid === campaign.uuid
              )?.media_url;
              return (
                <SingleBottle
                  key={campaign.uuid}
                  campaign={campaign}
                  campaignImage={campaignImageUrl!}
                  currentOrders={ordersData[campaign.uuid]}
                />
              );
            })}
          </div>
          {campaigns.length ? (
            <div className="mt-4 flex justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          ) : (
            // <div>No Campaigns Found</div>
            <></>
          )}
        </>
      )}{" "}
      {campaigns?.length === 0 && (
        <>
          <div className="border border-dashed text-center border-gray-400 mt-4 mx-auto max-w-[450px] rounded-lg px-10 py-20">
            No campaigns found
          </div>
        </>
      )}
      {!campaigns && (
        <>
          <Stack spacing={1}>
            <Stack spacing={1} direction={"row"}>
              <Skeleton variant="rounded" width={400} height={200} />
              <Skeleton variant="rounded" width={400} height={200} />
              <Skeleton variant="rounded" width={400} height={200} />
            </Stack>
            <Stack spacing={1} direction={"row"}>
              <Skeleton variant="rounded" width={400} height={200} />
              <Skeleton variant="rounded" width={400} height={200} />
              <Skeleton variant="rounded" width={400} height={200} />
            </Stack>
          </Stack>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.main`
  .flex.cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    flex-wrap: wrap;
    gap: 20px;
  }
  .filter {
    display: flex;
    gap: 1.4rem;
    flex-wrap: wrap;
  }
  .sort {
    position: relative;
  }
  .sort::after {
    content: "";
    position: absolute;
    top: 10%;
    right: -0.52rem;
    height: 90%;
    background-color: black;
    width: 3px;
  }
`;
