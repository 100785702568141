import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { DashboardTitle } from '../../components/DashboardTitle';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { FiPhone, FiUsers } from 'react-icons/fi';
import { AiOutlineCloseSquare, AiOutlineHeart } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { RiDeleteBackLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const cards = [
  {
    header: 'Self-Help Articles',
    // icon: <AiOutlineHeart />,
    body: 'Self-care is an import aspect of mainting overall health and well-being.',
    icon: <AiOutlineHeart className="text-lg sm:text-3xl" />,
    path: '/articles',
  },
  {
    header: 'Customer Support',
    // icon: <FiUsers />,
    body: 'The range of services provided to customers to help them with their inquiries.',
    icon: <FiUsers className="text-lg sm:text-3xl" />,
    path: '/',
  },
  {
    header: 'Phone & Email',
    // icon: <BsTelephone />,
    body: 'If you have any questions or concerns, please feel free to contact us.',
    icon: <FiPhone className="text-lg sm:text-3xl" />,
    path: '/contact-us',
  },
  {
    header: 'Delete My Account',
    // icon: <RiDeleteBackLine />,
    body: 'If you wish to delete your account, please follow the provided instructions.',
    icon: <AiOutlineCloseSquare className="text-lg sm:text-3xl" />,
    path: '/',
  },
];

export const HelpCenter = ({ showTitle = true }: { showTitle: boolean }) => {
  return (
    <section>
      {showTitle && <DashboardTitle text="Help Center"></DashboardTitle>}

      <div className="mt-4 grid md:grid-cols-2 gap-5 lg:gap-10">
        {cards.map((card) => (
          <Link to={card.path}>
            <div className="rounded-lg shadow-md p-8 lg:py-10 lg:px-14 flex items-center gap-4 lg:gap-6">
              {/* <img src="" alt="" /> */}
              <div className="text-3xl">{card.icon}</div>
              <div>
                <h4 className="font-bold text-xl">{card.header}</h4>
                <p className="">{card.body}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="mt-[100px] ">
        <div className="dashboard_subtitle  font-bold mt-6">FAQs</div>

        <div className="grid w-full items-start mt-4 md:grid-cols-2 mx-auto  gap-[50px] xl:gap-x-10">
          <Accordion className="  ">
            <div className="border-2 border-solid border-[#CCCCCC] rounded-[27px] py-4  font-bold text-center">
              Basics
            </div>
            <AccordionItem
              header="What is Faith Grey"
              className="py-3 border-b border-black"
            >
              Faith Grey is an innovative platform that revolutionizes the way
              people purchase everyday household items (e.g. kitchen appliances,
              furniture, groceries) through group campaigns. The platform
              operates on the principle of collective buying power, where
              individuals come together to back campaigns for specific household
              items. As more people join and support a campaign, the price of
              the household item(s) gradually decreases until it reaches a
              predetermined end goal. This unique model allows customers to
              benefit from significant price reductions and ensures that once a
              production goal is met, the campaign delivers on the promised
              items. <br /> <br /> The core concept behind Faith Grey is to
              harness the power of the crowd to secure better deals on
              high-quality household items. By pooling the purchasing power of
              multiple individuals, the platform empowers customers to access
              exclusive discounts that may not be available through traditional
              retail channels. This collaborative approach encourages community
              engagement and fosters a sense of camaraderie among like-minded
              enthusiasts who want to utilize technology to save on everyday
              household items. <br />
              <br /> When a campaign on Faith Grey reaches its production goal,
              the platform proceeds to fulfill the orders by
              manufacturing/producing and delivering the campaigned items. This
              model ensures that customers who have backed a campaign will
              receive the household items they desire once the target is
              achieved. The process is transparent and efficient, offering
              participants a sense of fulfillment as they witness the campaign
              progress and witness the price dropping as more people join in.
              Ultimately, Faith Grey aims to provide a win-win situation for
              both customers and manufacturers/producers, as buyers secure
              affordable household items, and producers benefit from bulk orders
              and reduced marketing costs. <br />
              <br /> In conclusion, Faith Grey is a pioneering platform that
              revolutionizes the way people purchase household items through
              group campaigns. By leveraging collective buying power, Faith Grey
              allows customers to secure significant price reductions on
              high-quality household items. The platform&#39;s unique approach
              fosters community engagement and ensures that once a campaign
              reaches its production goal, the promised household item is
              delivered to the backers. With Faith Grey, the future of everyday
              household items shopping becomes a collaborative and cost-
              effective endeavor, benefiting both consumers and
              manufacturers/producers alike.
            </AccordionItem>

            <AccordionItem
              header="What are the basics?"
              className="py-3 border-b border-black"
            >
              On Faith Grey, a campaign is a specific project with a clear goal,
              like buying a blender. The funding/production goal is the amount
              needed to complete the project. Similar to Kickstarter, Faith Grey
              operates on an all-or-nothing basis, where backers are refunded if
              a campaign does not reach its funding/production goal.
              <br />
              <br />
              Faith Grey is not a store; it's a collaborative platform. <br />
              <br />
              To learn more about Faith Grey, browse through our campaigns.
              Faith Grey provides a platform for a community to form to increase
              their purchasing power while cutting out the multiple middlemen
              cost.
            </AccordionItem>

            <AccordionItem
              header="Why are your prices low? Is Faith Grey a scam?"
              className="py-3 border-b border-black"
            >
              Many people wonder why the prices on Faith Grey are lower compared
              to traditional retail channels and may question if Faith Grey is a
              scam. However, there are legitimate reasons behind the affordable
              prices on Faith Grey, and it is important to understand that Faith
              Grey is a trustworthy platform. Here&#39;s why the prices are low
              and why Faith Grey is not a scam. <br />
              <br />
              Firstly, the low prices on Faith Grey can be attributed to the
              unique group purchasing model it operates on. Through campaigns,
              individuals come together to back a specific household item. As
              more people join and support a campaign, the price gradually
              decreases until it reaches the end goal. This collective buying
              power allows Faith Grey to negotiate better deals with
              manufacturers and suppliers, resulting in cost savings that are
              passed on to customers. It&#39;s a win- win situation where
              customers can access quality everyday household items at reduced
              prices. <br />
              <br />
              Secondly, Faith Grey is not a scam but a legitimate platform for
              people looking to save on everyday household items. It operates
              transparently, and the campaign progress can be tracked by all
              participants through our updates. Faith Grey is built on a secure
              and reliable platform. While there may be scams in the online
              world, it&#39;s crucial to differentiate Faith Grey as a
              legitimate platform that follows ethical practices, is registered
              legally to conduct business in Ghana and ensures the fulfillment
              of campaign promises. <br />
              <br />
              In conclusion, the low prices on Faith Grey are a result of the
              group purchasing model that leverages collective buying power.
              Faith Grey is not a scam but a reputable platform that operates
              transparently and fosters trust within our community. By
              understanding the unique pricing model, customers can confidently
              explore and support campaigns on Faith Grey to enjoy affordable
              everyday household items.
            </AccordionItem>

            <AccordionItem
              header="Why is funding all or nothing?"
              className="py-3 border-b border-black"
            >
              The all-or-nothing funding model on Faith Grey safeguards backers.
              If a campaign doesn&#39;t meet its funding/production goal,
              charges will be refunded back. This ensures campaigns have the
              necessary resources to fulfill their promises. It also assures
              backers that they support campaigns with a higher chance of
              success. <br />
              <br />
              This model creates urgency and builds a community spirit among
              backers. It fosters collective effort and a shared sense of
              accomplishment. Backers must unite to raise the required funds,
              making the process democratic and fair. <br />
              <br />
              Campaigns have their funding goals and deadlines on Faith Grey.
              Clear communication is crucial, and any changes or delays will be
              openly communicated. <br />
              <br />
              Faith Grey&#39;s all-or-nothing funding protects campaigns,
              motivates backers, and promotes transparency. It ensures viable
              campaigns and supports sustainable creative practices.
            </AccordionItem>

            <AccordionItem
              header="Common Account Issues"
              className="py-3 border-b border-black"
            >
              <b>How do I log in with Facebook?</b>
              <br />
              <br />
              Simply click on “Continue with Facebook” on the Login page to
              login with Facebook.
              <b>
                How do I set a new password? I forgot my password, how do I log
                in?
              </b>{' '}
              <br />
              <br />
              To set a new password, please follow these steps:
              <ol>
                <li>Go to faithgrey.com and select “Login”</li>
                <li>Select “Forgot Your Password”</li>
                <li>
                  You’ll then be prompted to enter your email address associated
                  with your account
                </li>
                <li>
                  You’ll receive an email to set a new password with a link
                </li>
                <li>
                  Click on the link in the email which will prompt you to set a
                  new password
                </li>
              </ol>
              If you’re already logged in, please log out and then follow these
              steps.
            </AccordionItem>
          </Accordion>

          <Accordion className="">
            <div className="border-2 border-solid border-[#CCCCCC] rounded-[27px] py-4  font-bold text-center">
              Backer Questions
            </div>
            <AccordionItem
              header="How do I know if my campaign towards a campaign went through?"
              className="py-3 border-b border-black"
            >
              If you didn&#39;t receive a &quot;Thank you!&quot; confirmation
              page or a confirmation email titled &quot;You just backed
              [Campaign Name],&quot; your campaign backing likely didn&#39;t go
              through. You may also receive an error message on the campaign
              backing page. If you&#39;re unable to back a campaign initially,
              it could be due to authentication requirements from your mobile
              wallet/service provider or bank. Try using a desktop if your
              mobile device doesn&#39;t support the required authentication.
            </AccordionItem>

            <AccordionItem
              header="I am having trouble backing a campaign, what can I do?"
              className="py-3 border-b border-black"
            >
              Most campaign backing issues stem from mobile wallet and
              card-related problems. Troubleshooting steps for mobile include
              double checking the phone number associated with your mobile
              wallet. Troubleshooting steps for card include double-checking the
              card details, contacting your bank to ensure no holds or
              additional authorizations are required, ensuring sufficient funds
              on pre-paid or debit cards, and informing your bank or credit card
              provider about Faith Grey&#39;s authorization process. If errors
              persist, try using a different card.
            </AccordionItem>

            <AccordionItem
              header="My backed campaign was declined. How can I retry my payment?"
              className="py-3 border-b border-black"
            >
              If your backed campaign is declined, you have the opportunity to
              retry the payment. You can retry using a different mobile wallet
              number by backing the campaign you were declined on. Some declines
              may occur due to authentication requirements or hard declines from
              your bank or mobile service/wallet provider. Contact your bank or
              mobile service/wallet provider for clarification and reattempt the
              payment.
            </AccordionItem>

            <AccordionItem
              header="How will I know when my backed campaign has gone through?"
              className="py-3 border-b border-black"
            >
              Once your backed campaign is successfully processed, you&#39;ll
              receive a confirmation email at the address linked to your Faith
              Grey account. Rest assured, you will only be charged for the
              campaign you back. If the payment isn&#39;t processed within a few
              days, your backed campaign will be dropped.
            </AccordionItem>

            <AccordionItem
              header="I&#39;m seeing multiple charges for the same backed campaign."
              className="py-3 border-b border-black"
            >
              Typically, you’re charged the end goal price once you back a
              campaign and a remaining amount if the end goal price wasn’t
              achieved at the end of a campaign, but temporary authorizations
              may occur. If you notice multiple charges for the same amount,
              check if you backed the campaign using multiple Faith Grey
              accounts. Reach out to friends and family who may have access to
              your devices or other email addresses associated with Faith Grey.
              If uncertainty remains, contact Faith Grey&#39;s Support team for
              further assistance.
            </AccordionItem>
          </Accordion>
        </div>

        <div className="flex items-center justify-center mt-[100px] ">
          <div className="flex items-center justify-center gap-3 border border-black rounded-full px-5 py-2 ">
            <button onClick={() => (window.location.href = '/articles')}>
              View More
            </button>
            <BsArrowRight className="font-bold" />
          </div>
        </div>
      </div>
    </section>
  );
};
