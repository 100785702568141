import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/InputField";
import { CiMail } from "react-icons/ci";
import { AiOutlineLock } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { BlackButton } from "../../../components/black-button";
import api from "../../../api/api";
import { environment } from "../../../environments/environment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../store/authentication/actions/actions";
import rainbowBg from "../../../assets/images/rainbow-bg.png";

export const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      const response = await api.post(`${environment.baseUrl}/auth/login`, {
        email: values.email,
        password: values.password,
      });

      // Check if the login was successful
      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.removeItem("user_a");
        localStorage.removeItem("token_a");

        // check if there's a campaign the user wants to back
        const campaignToBack = sessionStorage.getItem("campaignToBack");
        const quantity = sessionStorage.getItem("quantity");
        const amount = sessionStorage.getItem("amount");
        const size = sessionStorage.getItem("size");
        if (campaignToBack) {
          navigate(
            `/back-campaign/${campaignToBack}?quantity=${quantity}&amount=${amount}&size=${size}`
          );
        } else {
          navigate("/campaigns");
        }
        dispatch(loginSuccess(response.data.data.token));
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const googleSignIn = async () => {
    try {
      const response = await api.get("/auth/google/signin");

      if (response.data.success) {
        window.location.replace(response.data.data.redirectUrl);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <Wrapper className="web_padding_x web_padding_t grid gap-20 lg:grid-cols-[40%_1fr]">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, values }) => (
          <Form className="fields w-full mx-auto max-w-[550px] lg:pb-20">
            <h2 className="bar_above web_heading">Login</h2>
            <div className="flex flex-col items-start gap-4 mt-6 tall:mt-10 2xl:mt-10">
              <InputField
                label="Email"
                name="email"
                placeholder="luudu@gmail.com"
                icon={<CiMail />}
                type="email"
                required={true}
              />
              <InputField
                label="Password"
                name="password"
                icon={<AiOutlineLock />}
                type="password"
                required={true}
              />

              <div className="mt-5 w-full mx-auto max-w-[350px] lg:w-[75%] lg:mx-[unset] grid gap-4">
                <BlackButton
                  className="w-full"
                  text="Sign in"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                ></BlackButton>
                <p className="justify-self-center font-bold">or</p>
                <Button
                  className="w-full"
                  name="Continue with Facebook"
                  bg="#3479EA"
                  color="#fff"
                  social={<FaFacebookF style={{ color: "#3479EA" }} />}
                />
                <Button
                  className="w-full"
                  name="Continue with Google"
                  bg="#3479EA"
                  color="#fff"
                  onClick={googleSignIn}
                  social={<FcGoogle />}
                />
                <div>
                  <Link
                    to="/login-face-id"
                    className="block underline mb-3 hover:font-semibold text-center lg:text-left underline-offset-2"
                  >
                    Sign in with Face ID
                  </Link>
                  <Link
                    to="/forgot-password"
                    className="block underline mb-3 hover:font-semibold text-center lg:text-left underline-offset-2"
                  >
                    Forgot Your Password?
                  </Link>
                  <Link
                    to="/signup"
                    className="block underline mb-3 hover:font-semibold text-center lg:text-left underline-offset-2"
                  >
                    Create a new account
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div
        style={{
          backgroundImage: `url(${rainbowBg})`,
        }}
        className="card py-14 px-10 lg:mt-10"
      >
        <h3 className="web_heading">Hello!</h3>
        <p className="max-w-[254px]">
          Enter your personal details and start your journey with us.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  div.card {
    h3 {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    align-items: start;
    gap: 70px;
    margin-top: 2.5rem;

    p.self-center {
      position: relative;
      left: 30%;
      transform: translateX(-30%);
      align-self: unset;
    }
  }
`;
