import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { BlackButton } from '../../components/black-button';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { toast } from 'react-toastify';

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  subject: string;
  message: string;
}

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  subject: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

export const ContactUs = () => {
  return (
    <main className="my-10 lg:px-16 px-4">
      <div className="lg:mx-40 mx-0">
        <h2 className="web_heading bar_above">Contact Us</h2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            values: FormData,
            { setSubmitting, resetForm }: FormikHelpers<FormData>
          ) => {
            try {
              const response = await axios.post(
                `${environment.formspeeEndpoint}`,
                values
              );

              if (response.status === 200) {
                toast.success('Message sent successfully');
                console.log('Message sent successfully');
                resetForm();
              } else {
                toast.error('An error occurred while submitting the form.');
                console.error('An error occurred while submitting the form.');
              }
            } catch (error) {
              console.error(
                'An error occurred while submitting the form:',
                error
              );
              toast.error('An error occurred while submitting the form.');
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isValid, isSubmitting, errors, touched }) => (
            <Form className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <div className="space-y-1.5">
                <h6 className="font-bold text-sm md:text-base">First Name </h6>
                <Field
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  className={`rounded-full w-full border border-black px-4 py-2 ${
                    touched.first_name && errors.first_name
                      ? 'border-red-500'
                      : ''
                  }`}
                />
                {touched.first_name && errors.first_name && (
                  <div className="text-red-500">{errors.first_name}</div>
                )}
              </div>

              <div className="space-y-1.5">
                <h6 className="font-bold text-sm md:text-base">Last Name </h6>
                <Field
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  className={`rounded-full w-full border border-black px-4 py-2  ${
                    touched.last_name && errors.last_name
                      ? 'border-red-500 border-2'
                      : ''
                  }`}
                />
                {touched.last_name && errors.last_name && (
                  <div className="text-red-500">{errors.last_name}</div>
                )}
              </div>

              <div className="space-y-1.5">
                <h6 className="font-bold text-sm md:text-base">Email</h6>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className={`rounded-full w-full border border-black px-4 py-2  ${
                    touched.last_name && errors.last_name
                      ? 'border-red-500 border-2'
                      : ''
                  }`}
                />
                {touched.email && errors.email && (
                  <div className="text-red-500">{errors.email}</div>
                )}
              </div>

              <div className="space-y-1.5">
                <h6 className="font-bold text-sm md:text-base">Subject</h6>
                <Field
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  className={`rounded-full w-full border border-black px-4 py-2  ${
                    touched.last_name && errors.last_name
                      ? 'border-red-500 border-2'
                      : ''
                  }`}
                />
                {touched.subject && errors.subject && (
                  <div className="text-red-500">{errors.subject}</div>
                )}
              </div>

              <div className="md:col-span-2 space-y-1.5">
                <h6 className="font-bold text-sm md:text-base">Message</h6>
                <Field
                  as="textarea"
                  name="message"
                  id="message"
                  placeholder="Write your message"
                  className={`rounded-lg lg:w-[70%] w-full border border-black px-4 py-2  ${
                    touched.message && errors.message
                      ? 'border-red-500 border-2'
                      : ''
                  }`}
                  rows={6}
                />
                {touched.message && errors.message && (
                  <div className="text-red-500">{errors.message}</div>
                )}
              </div>

              <div className="md:col-span-2 mt-6">
                <BlackButton
                  text="Submit"
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                ></BlackButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
};
