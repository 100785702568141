export const Articles = () => {
  return (
    <main className="my-10 lg:px-16 px-4">
      <div>
        <h2 className="web_heading bar_above">Articles</h2>

        <div className="my-4">
          <h3 className="font-bold pt-3 text-xl">What is Luudu?</h3>
          <p className="pt-3">
            Luudu is an innovative platform that revolutionizes the way people
            purchase footwear through group campaigns. The platform operates on
            the principle of collective buying power, where individuals come
            together to back campaigns for specific shoe styles. As more people
            join and support a campaign, the price of the footwear gradually
            decreases until it reaches a predetermined end goal. This unique
            model allows customers to benefit from significant price reductions
            and ensures that once a production goal is met, the campaign
            delivers on the promised items.
          </p>
          <p className="pt-3">
            The core concept behind Luudu is to harness the power of the crowd
            to secure better deals on high-quality footwear. By pooling the
            purchasing power of multiple individuals, the platform empowers
            customers to access exclusive discounts that may not be available
            through traditional retail channels. This collaborative approach
            encourages community engagement and fosters a sense of camaraderie
            among like-minded shoe enthusiasts.
          </p>
          <p className="pt-3">
            When a campaign on Luudu reaches its production goal, the platform
            proceeds to fulfill the orders by manufacturing and delivering the
            campaigned items. This model ensures that customers who have backed
            a campaign will receive the footwear they desire once the target is
            achieved. The process is transparent and efficient, offering
            participants a sense of fulfillment as they witness the campaign
            progress and witness the price dropping as more people join in.
            Ultimately, Luudu aims to provide a win-win situation for both
            customers and manufacturers, as buyers secure affordable footwear,
            and producers benefit from bulk orders and reduced marketing costs.
          </p>
          <p className="pt-3">
            In conclusion, Luudu is a pioneering platform that revolutionizes
            the way people purchase footwear through group campaigns. By
            leveraging collective buying power, Luudu allows customers to secure
            significant price reductions on high-quality shoes. The
            platform&#39;s unique approach fosters community engagement and
            ensures that once a campaign reaches its production goal, the
            promised footwear is delivered to the backers. With Luudu, the
            future of footwear shopping becomes a collaborative and
            cost-effective endeavor, benefiting both consumers and manufacturers
            alike.
          </p>

          <h3 className="font-bold pt-3 text-xl">
            I haven&#39;t received my items yet?
          </h3>
          <p className="pt-3">
            If you haven&#39;t received your items from a Luudu campaign, there
            are steps you can take to gather information and stay updated on the
            status of your reward:
          </p>
          <ol className="list-decimal ml-8">
            <li>
              Check the estimated delivery date: Start by reviewing the
              estimated delivery date specified under Campaign tab under the
              image of the product item being campaigned for within the
              Campaign. Remember that this date is an estimate, not a guarantee.
            </li>
            <li>
              Check the Updates section: Visit the project page and navigate to
              the Updates section. This is where information is shared about the
              campaign&#39;s progress. If it has been a while since the last
              update, you can anonymously request a new update.
            </li>
            <li>
              Understand potential delays: Keep in mind that unexpected
              challenges or underestimated timelines can lead to delays.
              Campaigns may face obstacles or encounter situations that affect
              the campaign&#39;s timeline. Unlike an online shop, there is a
              possibility of unforeseen circumstances that can impact the
              completion of the campaign as initially planned.
            </li>
            <li>
              Expect communication from Luudu: When facing delays, we&#39;ll
              communicate setbacks and keep backers informed. Luudu will provide
              updates on the campaign&#39;s progress and any challenges they are
              facing. This transparency helps backers understand the current
              status and any revised timelines.
            </li>
          </ol>
          <p className="pt-1.5">
            By following these steps, you can gather relevant information to
            stay informed about the progress of the campaign you backed.
          </p>

          <h3 className="font-bold pt-3 text-xl">
            Common Campaign Backing Problems
          </h3>
          <h4 className="font-semibold pt-3 text-lg">
            How do I know if campaign I backed went through?
          </h4>
          <p className="pt-3">
            If you didn&#39;t receive a &#8223;Thank you!&#8221; confirmation
            page or a confirmation email titled &#8223;You just backed [Campaign
            Name],&#8221; your campaign backing likely didn&#39;t go through.
            You may also receive an error message on the campaign backing page.
            If you&#39;re unable to back a campaign initially, it could be due
            to authentication requirements from your bank or credit card
            provider. Try using a desktop if your mobile device doesn&#39;t
            support the required authentication.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I am having trouble backing a campaign, what can I do?
          </h4>
          <p className="pt-3">
            Most campaign backing issues stem from card-related problems.
            Troubleshooting steps include double-checking the card details,
            contacting your bank or credit card provider to ensure no holds or
            additional authorizations are required, ensuring sufficient funds on
            pre-paid or debit cards, and informing your bank or credit card
            provider about Luudu&#39;s authorization process. If errors persist,
            try using a different card.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            My backed campaign was declined. How can I retry my payment?
          </h4>
          <p className="pt-3">
            If your backed campaign is declined, you have the opportunity to
            retry the payment. You can retry using a different card by backing
            the campaign you were declined on. Some declines may occur due to
            authentication requirements or hard declines from your bank or
            credit card provider. Contact your bank or credit card provider for
            clarification and reattempt the payment. For Apple Pay backed
            campaigns, retrying via Apple Pay may not be possible, so add card
            details manually or choose a saved card instead.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            How will I know when my backed campaign has gone through?
          </h4>
          <p className="pt-3">
            Once your backed campaign is successfully processed, you&#39;ll
            receive a confirmation email at the address linked to your Luudu
            account. Rest assured, you will only be charged for the campaign you
            back. If the payment isn&#39;t processed within a few days, your
            backed campaign will be dropped.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I have a new email address and need help accessing backed campaigns
            on an older account.
          </h4>
          <p className="pt-3">
            Contact Luudu&#39;s Support team if you have a new email address and
            need assistance accessing backed campaigns on an older account. They
            will guide you through the process of updating your email address
            after verifying your identity.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I&#39;m seeing multiple charges for the same backed campaign.
          </h4>
          <p className="pt-3">
            Typically, you&#39;re charged the end goal price once you back a
            campaign and a remaining amount if the end goal price wasn&#39;t
            achieved at the end of a campaign, but temporary authorizations may
            occur. If you notice multiple charges for the same amount, check if
            you backed the campaign using multiple accounts. Reach out to
            friends and family who may have access to your devices or other
            email addresses associated with Luudu. If uncertainty remains,
            contact <b>Luudu&#39;s Support team for further assistance.</b>
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I&#39;m having trouble authenticating my backed campaign.
          </h4>
          <p className="pt-3">
            For users in certain countries, strong customer authentication
            &#40;SCA&#41; may require additional authentication during the
            campaign backing process. To troubleshoot, verify that the card
            information is correct, update your browser to the latest version,
            clear browser cache and cookies, try authorizing the backed campaign
            on a different browser or device, and contact your bank or credit
            card provider for assistance. Provide specific details about the
            charge, mention validation attempts, and inquire about authorization
            system compatibility.
          </p>
          <p className="pt-3">
            If problems persist, take note of the steps you&#39;ve taken and any
            error messages received, then reach out to Luudu&#39;s Support team
            for further guidance. They are dedicated to helping resolve backed
            campaign-related issues and ensuring a smooth experience on the
            platform.
          </p>

          <h3 className="font-bold pt-3 text-xl">Common Account Issues</h3>
          <h4 className="font-semibold pt-3 text-lg">
            How do I log in with Facebook?
          </h4>
          <p className="pt-3">
            Simply click on &#8223;Continue with Facebook” on the Login page to
            login with Facebook.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            How do I set a new password? I forgot my password, how do I log in?
          </h4>
          <p className="pt-3">
            To set a new password, please follow these steps:
          </p>
          <ol className="list-decimal ml-8">
            <li>Go to luudu.com and select &#8223;Login&#8221;</li>
            <li>Select &#8223;Forgot Your Password&#8221;</li>
            <li>
              You&#39;ll then be prompted to enter your email address associated
              with your account
            </li>
            <li>
              You&#39;ll receive an email to set a new password with a link
            </li>
            <li>
              Click on the link in the email which will prompt you to set a new
              password
            </li>
          </ol>
          <p className="pt-1">
            If you&#39;re already logged in, please log out and then follow
            these steps.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            How can I change the email address associated with my account?
          </h4>
          <p className="pt-3">
            To update the email address associated with your Luudu account
            you&#39;ll first need to login to your account using the email
            address currently associated with it. Once you&#39;ve logged in:
          </p>
          <ol className="list-decimal ml-8">
            <li>
              Head over to your Account Profile and replace your old email with
              the email address you wish to use instead.
            </li>
            <li>
              Under &#8223;Edit Profile&#8221;, change your email address to the
              decided one
            </li>
            <li>
              Confirm your changes by clicking &#8223;Save,&#8221; and you
              should see a pop-up screen confirming your changes.
            </li>
          </ol>
          <p className="pt-1">
            For security reasons, we require all Luudu users to verify their
            email addresses. So keep an eye out for a verification email from us
            in order to finalize your email address change.
          </p>
          <p className="pt-2">
            Please note: it isn&#39;t possible to use an email address on more
            than one Luudu account. If you are seeing an error message that the
            email you are trying to enter is already in use, we recommend
            entering an alternative email address.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            My email address is not recognized when I try to reset my password?
          </h4>
          <p className="pt-3">
            If your email address is not recognized when trying to send the
            password reset email, it&#39;s possible you may have used a
            different email address to register your account.
          </p>
          <p className="pt-3">
            If you&#39;re certain that you&#39;re entering the correct email, it
            could be that the email address entered may have had a typo or a
            variant spelling. In this case, you&#39;ll need to get in touch with
            us to answer a few security questions in order for us to locate your
            Luudu account.
          </p>
          <p className="pt-3">
            Please contact our Support team and we&#39;ll be happy to help you
            with this process as quickly as possible. Please have the details of
            your most recent campaign backing handy before contacting us.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I&#39;m not receiving emails from Luudu
          </h4>
          <p className="pt-3">
            If you&#39;re having difficulty receiving emails from Luudu for a
            project you&#39;ve backed, we recommend first double-checking your
            email notification settings. To do so, sign in to your Luudu account
            profile and go to Notifications Preferences. Here you&#39;ll see
            icons for email and mobile notifications that can be turned on or
            off by toggling the corresponding icons.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I received a notification stating emails from Luudu have been
            bouncing
          </h4>
          <p className="pt-3">
            As a first step, follow the link provided on the notification in
            order to check the email address that is currently tied to your
            Luudu account and update it if necessary.
          </p>
          <p className="pt-3">
            If everything is in order, meaning the email address is correct
            &#40;i.e. free of typos&#41; and active, we encourage you to add
            no-reply@luudu.com to your contacts list. This helps to ensure that
            our emails to you are not being intercepted by a spam filter.
          </p>
          <p className="pt-3">
            If you&#39;re still not receiving emails from Luudu, it&#39;s
            possible that there may be an issue with your email provider. Please
            try reaching out to them next, to see if anything might be going on
            from their end.
          </p>
          <p className="pt-3 semibold">
            In instances where you have tried all of the above and are still
            experiencing email delivery issues, please contact our Support
            team for further troubleshooting.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            I&#39;m unable to complete the CAPTCHA challenge to access Luudu
          </h4>
          <p className="pt-3">
            If you are having difficulty completing the CAPTCHA challenge, the
            first step you should take is to report the issue directly to
            PerimeterX by clicking the &#8223;Report an issue&#8221; button in
            the footer. Make sure to have the reference ID ready &#40;this
            appears on the challenge page&#41;.
          </p>
          <p className="pt-3">
            Please note: The challenge page requires Javascript and cookies to
            be enabled, additionally some browser extensions, including ad
            blockers, may block it from loading.
          </p>

          <h3 className="font-bold pt-3 text-xl">
            Can I cancel a campaign backing?
          </h3>
          <p className="pt-3">
            All backings are currently final. We do accept returns if the goods
            you received are damaged or are not the right sizing.
          </p>

          <h3 className="font-bold pt-3 text-xl">How do I back a campaign?</h3>
          <p className="pt-3">
            Congratulations on finding a campaign you&#39;d like to support on
            Luudu! To ensure a smooth backing process, follow these steps. Guest
            campaign backing is not available, so make sure you&#39;re logged in
            to your Luudu account or create a new one if necessary.
          </p>
          <ol className="list-decimal ml-8">
            <li>
              Select the appropriate country from the drop-down menu and click
              &quot;Continue&quot; to proceed.
            </li>
            <li>
              Once on the main page, go to the campaign&#39;s page, select your
              size, select your quantity and click the green &quot;Back this
              Campaign&quot; button.
            </li>
            <li>
              If the backed campaign involves shipping, select the appropriate
              country from the drop-down menu and click &quot;Continue&quot; to
              proceed.
            </li>
            <li>
              For digital products, there won&#39;t be a shipping option. If a
              campaign is marked for in-person local receipt, the location will
              be listed with the campaign backed.
            </li>
            <li>
              On the next page, confirm your campaign backing. After confirming,
              click the green &quot;Back this Campaign&quot; button.
            </li>
            <li>
              Double-check your backed campaign details and shipping
              information. When ready, click &quot;Continue&quot; to proceed to
              the payments page.
            </li>
            <li>
              Enter your payment details and check the box confirming your
              understanding that campaign items aren&#39;t guaranteed. In this
              case, a full refund will be issued.
            </li>
            <li>
              To complete your campaign backing, click the green &quot;Back this
              Campaign&quot; button. Please note that it&#39;s not possible to
              split a backed campaign between multiple credit cards. You&#39;ll
              need to provide one debit or credit card per backed campaign.
            </li>
            <li>
              Once your backed campaign is successfully processed, you should
              receive a confirmation email confirming your status as a backer of
              the campaign. You&#39;ll first be charged the end goal price once
              you back a campaign. If the campaign reaches its
              production/funding goal but not the end goal at the conclusion of
              the campaign, your card will be charged a difference of the final
              amount subtracted by the already charged end goal price.
            </li>
          </ol>
          <p className="pt-2">
            If you didn&#39;t see a &quot;Thank you!&quot; confirmation page or
            receive the confirmation email titled &quot;You just backed
            &#x5b;Campaign Name&#x5d;,&quot; it&#39;s likely that your backed
            campaign did not go through. In such cases, you may want to try
            again to ensure your support for the campaign is successfully
            recorded.
          </p>

          <h3 className="font-bold pt-3 text-xl">What are the basics?</h3>
          <p className="pt-3">
            On Luudu, a campaign is a specific project with a clear goal, like
            creating a new footwear collection. The funding/production goal is
            the amount needed to complete the project. Similar to Kickstarter,
            Luudu operates on an all-or-nothing basis, where backers are
            refunded if a campaign does not reach its funding/production goal.
          </p>
          <h4 className="font-semibold pt-3 text-lg">
            {' '}
            Luudu is not a store; it&#39;s a collaborative platform.
          </h4>
          <p className="pt-3">
            To learn more about Luudu, browse through our campaigns. Luudu
            brings together individuals with similar taste to make innovative
            footwear projects a reality without the middlemen cost.
          </p>

          <h3 className="font-bold pt-3 text-xl">When do I get charged?</h3>
          <p className="pt-3">
            You&#39;re charged the &#8223;end goal price&#8221; once you back a
            campaign. If a campaign is not successful in reaching the production
            goal at the conclusion of a campaign, you&#39;ll be refunded the
            &#8223;end goal price&#8221; you were charged. Similarly, if a
            campaign is canceled before it reaches its deadline, you&#39;ll be
            refunded the &#8223;end goal price&#8221; you were charged.
          </p>
          <p className="pt-3">
            If a campaign was to conclude with the final price landing between
            the &#8223;production goal price&#8221; and &#8223;end goal
            price&#8221;, Backers will be charged a final amount by subtracting
            the &#8223;end goal price&#8221; from the final price. Backers are
            not charged again if the &#8223;end goal price” is achieved at the
            conclusion of a campaign.
          </p>
          <h3 className="font-bold pt-3 text-xl">
            Why are your prices low? Is Luudu too good to be true?
          </h3>
          <p className="pt-3">
            Many people wonder why the prices on Luudu are lower compared to
            traditional retail channels and may question if Luudu is too good to
            be true. However, there are legitimate reasons behind the affordable
            prices on Luudu, and it is important to understand that Luudu is a
            trustworthy platform. Here&#39;s why Luudu can offer quality
            products at low prices.
          </p>
          <p className="pt-3">
            Firstly, the low prices on Luudu can be attributed to the unique
            group purchasing model it operates on. Through campaigns,
            individuals come together to back a specific footwear style. As more
            people join and support a campaign, the price gradually decreases
            until it reaches the end goal. This collective buying power allows
            Luudu to negotiate better deals with manufacturers and suppliers,
            resulting in cost savings that are passed on to customers. It&#39;s
            a win-win situation where customers can access quality footwear at
            reduced prices.
          </p>
          <p className="pt-3">
            Secondly, Luudu is a legitimate platform for footwear enthusiasts.
            It operates transparently, and the campaign progress can be tracked
            by all participants. Luudu is built on a secure and reliable
            platform. Luudu is a legitimate platform that follows ethical
            practices and ensures the fulfillment of campaign promises.
          </p>
          <p className="pt-3">
            In conclusion, the low prices on Luudu are a result of the group
            purchasing model that leverages collective buying power. Luudu is
            not a scam but a reputable platform that operates transparently and
            fosters trust among creators and backers. Customers can confidently
            explore and support campaigns on Luudu to enjoy affordable and
            stylish footwear options.
          </p>

          <h3 className="font-bold pt-3 text-xl">
            Why is funding all or nothing?
          </h3>
          <p className="pt-3">
            The all-or-nothing funding model on Luudu safeguards backers. If a
            campaign doesn&#39;t meet its funding/production goal, charges will
            be refunded back. This ensures campaigns have the necessary
            resources to fulfill their promises. It also assures backers that
            they support campaigns with a higher chance of success.
          </p>
          <p className="pt-3">
            This model creates urgency and builds a community spirit among
            backers. It fosters collective effort and a shared sense of
            accomplishment. Backers must unite to raise the required funds,
            making the process democratic and fair.
          </p>
          <p className="pt-3">
            Campaigns have their funding goals and deadlines on Luudu. Clear
            communication is crucial, and any changes or delays will be openly
            communicated.
          </p>
          <p className="pt-3">
            Luudu&#39;s all-or-nothing funding protects campaigns, motivates
            backers, and promotes transparency. It ensures viable campaigns and
            supports sustainable creative practices.
          </p>
        </div>
      </div>
    </main>
  );
};
