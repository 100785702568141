export const SizeGuide = () => {
  return (
    <main className="my-10 lg:px-8 px-4">
      <div className="lg:mx-40 mx-0">
        <h2 className="web_heading bar_above">Size Guide</h2>

        {/* Tables */}
        <div className="flex justify-center  gap-[5rem]  lg:flex-row flex-col ">
          <div>
            <h3 className="font-bold pt-3 mb-3 text-xl lg:text-center text-start">
              Women
            </h3>
            <table className="table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border-r border-b border-black px-4 py-2 text-center">
                    US
                  </th>
                  <th className="border-black border-b px-4 py-2 text-center">
                    UK
                  </th>
                  <th className="border-black border-b border-l px-4 py-2 text-center">
                    EU
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    3
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    35
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    5.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    3.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    35.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    6
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    4
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    36
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    6.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    4.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    36.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    7
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    37
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    7.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    5.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    37.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    8
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    6
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    38
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    8.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    6.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    38.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    9
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    7
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    39
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    9.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    7.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    39.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    10
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    8
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    40
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    10.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    8.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    40.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    11
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    9
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    41
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    11.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    9.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    41.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-black px-4 py-2 text-center">
                    12
                  </td>
                  <td className="border-black px-4 py-2 text-center">10</td>
                  <td className="border-l border-black px-4 py-2 text-center">
                    42
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* MEN TABLE */}
          <div>
            <h3 className="font-bold pt-3 mb-3 text-xl lg:text-center text-start">
              Men
            </h3>
            <table className="table-auto border-collapse ">
              <thead>
                <tr>
                  <th className="border-r border-b border-black px-4 py-2 text-center">
                    US
                  </th>
                  <th className="border-black border-b px-4 py-2 text-center">
                    UK
                  </th>
                  <th className="border-black border-b border-l px-4 py-2 text-center">
                    EU
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    7
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    6.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    40
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    7.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    7
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    40.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    8
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    7.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    41
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    8.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    8
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    42
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    9
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    8.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    42.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    9.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    9
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    43
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    10
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    9.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    44
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    10.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    10
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    44.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    11
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    10.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    45
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    11.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    11
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    45.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    12
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    11.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    46
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    12.5
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    12
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    46.5
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-b border-black px-4 py-2 text-center">
                    13
                  </td>
                  <td className="border-b border-black px-4 py-2 text-center">
                    12.5
                  </td>
                  <td className="border-l border-b border-black px-4 py-2 text-center">
                    47
                  </td>
                </tr>
                <tr>
                  <td className="border-r border-black px-4 py-2 text-center">
                    14
                  </td>
                  <td className=" border-black px-4 py-2 text-center">13.5</td>
                  <td className="border-l  border-black px-4 py-2 text-center">
                    48
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
};
