import howItWorksImg from '../../assets/maskedb.jpg';

export const HowItWorks2 = () => {
  return (
    <>
      <main className="flex flex-col md:flex-row items-center justify-between w-full mx-auto px-4 md:px-20 mb-20 gap-12 max-w-[1500px] md:mt-5">
        <div className="left-container w-full md:w-1/2">
          <h2 className="web_heading bar_above text-center md:text-left">How it works</h2>
          <p className="web_paragraph_1 mt-4">
            Luudu is a platform where campaigns can be brought to life through
            the collective effort of people. Our campaigns are designed to
            incentivize people to back campaigns into production while
            decreasing the price of the items being campaigned for. <br />
            <br /> Campaign Backers get charged the &ldquo;end goal price&rdquo;
            once they back a campaign. Campaign Backers will be issued a refund
            if the campaign&apos;s &ldquo;production goal&rdquo; is not met at
            the conclusion of a campaign. If a campaign was to conclude with the
            final price landing between the &ldquo;production goal price&rdquo;
            and &ldquo;end goal price&rdquo;, Campaign Backers will be charged a
            final amount by subtracting the &ldquo;end goal price&rdquo; from
            the final price. Campaign Backers are not charged again if the
            &ldquo;end goal price&rdquo; is achieved at the conclusion of a
            campaign. <br />
            <br /> Campaigns can still be backed even if an &ldquo;end goal
            price&rdquo; is achieved before the conclusion of a campaign
          </p>
        </div>
        <div className="right-container w-full md:w-1/2">
          <div className="relative w-full aspect-video overflow-hidden rounded-lg">
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/MBfNpRfyz6I?si=f8BXG463h7zjMfQ2"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </main>
    </>
  );
};