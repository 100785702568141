import React from "react";
import { ItemCountInput } from "../../components/item-count-input";
import {
  FaFacebookF,
  FaInstagram,
  FaSnapchatGhost,
  FaTwitter,
  FaTiktok,
} from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import bottle from "../../assets/images/about.png";
import { BlackButton } from "../../components/black-button";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";
interface IComment {
  name: string;
  time: string;
  comment: string;
  image: string | null;
}

export const ProductDetails = () => {
  const currencyView = "$";

  let comments: IComment[] = [
    {
      name: "Kane",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
    {
      name: "Sollie Mae",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
    {
      name: "Kane",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
    {
      name: "Sollie Mae",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
    {
      name: "Kane",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
    {
      name: "Sollie Mae",
      time: "(about 1hr ago)",
      comment:
        "I have a question about the FAQ section, regarding the shipping offered for adding products to the Q1 pro, does the shipping not work on the carrying cases?",
      image: null,
    },
  ];

  return (
    <>
      <main className="web_padding_x web_padding_y">
        <h2 className="web_heading bar_above">Product Detail Page</h2>

        <div className="grid mt-10 tall:mt-20 2xl:mt-20 gap-9 md:grid-cols-2 lg:grid-cols-[550px_440px]">
          <div>
            <Carousel
              autoPlay={false}
              infiniteLoop={true}
              interval={3000}
              axis="vertical"
              // showIndicators={false}
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              className="shop_item_carousel"
            >
              <div className="[aspect-ratio:298/350] tall:[aspect-ratio:559/800] 2xl:[aspect-ratio:559/800] bg-[#F3EFEC]">
                <img src={bottle} className="w-full h-full object-cover" />
              </div>
              <div className="[aspect-ratio:298/350] tall:[aspect-ratio:559/800] 2xl:[aspect-ratio:559/800] bg-[#F3EFEC]">
                <img src={bottle} className="w-full h-full object-cover" />
              </div>
              <div className="[aspect-ratio:298/350] tall:[aspect-ratio:559/800] 2xl:[aspect-ratio:559/800] bg-[#F3EFEC]">
                <img src={bottle} className="w-full h-full object-cover" />
              </div>
            </Carousel>
            <div>
              <div className="pt-8 px-6 text-center text-black bg-[#F8F8F8]">
                <h4 className="text-2xl md:text-[36px] font-bold">
                  Title Name
                </h4>
                <p className="text-sm mt-6 tall:mt-9 2xl:mt-9">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>

              <div className="rounded-b-full bg-[#F8F8F8] pb-32 px-6 text-center text-black">
                <div className="text-[32px] md:text-[49px] font-bold pt-3 tall:pt-6 2xl:pt-6">
                  {currencyView} 250
                </div>

                {/* icons */}
                <div className="flex gap-2 items-center mx-auto w-fit mt-4 tall:mt-8 2xl:mt-8">
                  <div className="w-[32px] h-[32px] bg-[#717171] rounded-full grid place-content-center">
                    <FaFacebookF className="text-white text-base" />
                  </div>
                  <div className="w-[32px] h-[32px] bg-[#717171] rounded-full grid place-content-center">
                    <FaInstagram className="text-white text-base" />
                  </div>
                  <div className="w-[32px] h-[32px] bg-[#717171] rounded-full grid place-content-center">
                    <FaSnapchatGhost className="text-white text-base" />
                  </div>
                  <div className="w-[32px] h-[32px] bg-[#717171] rounded-full grid place-content-center">
                    <FaTwitter className="text-white text-base" />
                  </div>
                  <div className="w-[32px] h-[32px] bg-[#717171] rounded-full grid place-content-center">
                    <FaTiktok className="text-white text-base" />
                  </div>
                </div>

                <p className="text-base mt-2">Share via</p>
              </div>
            </div>
          </div>

          <div>
            <h3 className="web_heading_2">Title Name</h3>

            <p className="text-sm mt-6 tall:mt-10 2xl:mt-10">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. Lorem
              Ipsum is simply dummy text of the printing and typesetting
              industry. <br /> <br /> Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown printer
              took a galley of type and scrambled it to make a type specimen
              book. It has survived not only five centuries, but also the leap
              into electronic typesetting, remaining essentially unchanged.
            </p>

            <div className="flex gap-8 items-center  mt-5 tall:mt-8 2xl:mt-8">
              <p className="text-sm md:text-base 2xl:text-xl tall:text-xl">
                Share via:
              </p>
              <div className="flex gap-2 items-center">
                <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
                  <FaFacebookF className="text-white text-sm" />
                </div>
                <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
                  <FaInstagram className="text-white text-sm" />
                </div>
                <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
                  <FaSnapchatGhost className="text-white text-sm" />
                </div>
                <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
                  <FaTwitter className="text-white text-sm" />
                </div>
                <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
                  <FaTiktok className="text-white text-sm" />
                </div>
              </div>
            </div>

            <div className="mt-10 tall:mt-16 2xl:mt-16 grid grid-cols-2 md:w-[80%] gap-5">
              <ItemCountInput />
              <BlackButton text="Add to Cart" className="text-sm"></BlackButton>
            </div>

            <div className="text-lg font-bold pb-5 tall:pb-7 2xl:pb-7 mt-10 tall:mt-16 2xl:mt-16 w-[132px] border-b-[4px] border-b-black border-solid">
              Comments
            </div>

            <div className="mt-10">
              <div className="border border-solid border-[#707070] px-4 py-5 flex items-center gap-6">
                <div className="rounded-full w-[53px] h-[53px] bg-black"></div>
                <div className="flex-1">
                  <p className="text-sm font-bold">Name</p>
                  <input
                    type="text"
                    placeholder="Enter your comment..."
                    className="outline-none placeholder:text-xs placeholder:text-[#CBCBCB]"
                  />
                </div>
              </div>

              <div className="max-h-[240px] mt-3 overflow-y-auto">
                {comments.map((comment, index) => (
                  <div
                    className={`px-4 py-5 flex items-center gap-6 ${
                      index % 2 ? "bg-[#F8F8F8]" : ""
                    }`}
                  >
                    <div className="rounded-full w-[53px] h-[53px] bg-black"></div>
                    <div className="flex-1">
                      <p className="text-sm font-bold">
                        {comment.name}{" "}
                        <span className="font-normal text-xs">
                          {comment.time}
                        </span>
                      </p>
                      <p className="text-xs">{comment.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
