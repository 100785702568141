import { useState, useEffect } from "react";

interface CookieConsentProps {
  onClose: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookie_consent");
    if (!cookieConsent) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookie_consent", "accepted");
    setIsOpen(false);
    onClose();
  };

  const handleDecline = () => {
    localStorage.setItem("cookie_consent", "declined");
    setIsOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-x-0 top-0 md:top-auto md:bottom-0 z-50 px-4 pt-24 md:p-0">
      <div className="mx-auto max-w-screen">
        <div className="relative bg-white shadow-xl border md:h-20 2xl:h-[5.5rem] border-gray-200 rounded-lg md:rounded-none">
          <div className="text-gray-800 mt-2 md:mt-0 text-center md:text-left p-6 md:px-8 md:py-2">
            <h3 className="text-lg md:text-base font-semibold mb-1.5 md:mb-0.5">
              Cookie Settings
            </h3>

            <p className="my-3 md:mb-1 md:my-0 text-gray-600 text-base md:text-xs 2xl:text-sm leading-snug">
              We use cookies to enhance your browsing experience, serve
              personalized content, and analyze our traffic. These cookies help
              us understand how you interact with our website. By clicking
              "Accept", you consent to our use of cookies.
            </p>
            <div className="flex justify-center md:justify-end gap-5 mt-1">
              <button
                onClick={handleDecline}
                className="text-sm md:text-xs 2xl:text-sm font-medium text-gray-500 hover:text-gray-700 transition duration-200 border border-black px-4 py-0.5"
              >
                Continue without accepting
              </button>
              <button
                onClick={handleAccept}
                className="px-5 py-0.5 bg-[#78D1E7] text-black  focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-xs 2xl:text-sm focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
