import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IoTrashOutline,
  IoCloudUploadOutline,
  IoRefresh,
  IoAddCircleOutline,
  IoPencil,
} from "react-icons/io5";
import { environment } from "../../environments/environment";
import api from "../../api/api";
import { toast } from "react-toastify";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Spinner from "../../components/ui/Spinner";

interface GalleryItemProp {
  uuid?: string;
  sort_id?: number;
  media_url: string;
  media_description: string;
  media_title: string;
  media_type: "image" | "video";
  preview?: string;
}

interface DraggableItemProps {
  item: GalleryItemProp;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  deleteItem: (index: number) => void;
  onEdit: (index: number) => void;
}

export const AdminGallery: React.FC = () => {
  const [items, setItems] = useState<GalleryItemProp[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingTitle, setEditingTitle] = useState<string>("");
  const [editingDescription, setEditingDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading images...");

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        const base64String = result.replace("data:", "").replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setSelectedFiles(files);

    const newItems: GalleryItemProp[] = files.map((file) => ({
      media_url: URL.createObjectURL(file),
      preview: URL.createObjectURL(file),
      media_title: file.name,
      media_description: "Newly added item",
      media_type: file.type.startsWith("image/") ? "image" : "video",
      sort_id: items.length + 1,
    }));

    // console.log(newImages);

    setItems((prevItems) => [...prevItems, ...newItems]);
  };

  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);

    const updatedItemsWithSort = updatedItems.map((item, idx) => ({
      ...item,
      sort_id: idx + 1,
    }));
    setItems(updatedItemsWithSort);
  };

  const updateGalleryImages = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage("Uploading gallery images");
      // const MAX_FILE_SIZE_MB = 10;

      // for (const file of selectedFiles) {
      //   if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      //     toast.error(
      //       `File ${file.name} exceeds the maximum size of ${MAX_FILE_SIZE_MB}MB`
      //     );
      //     setIsLoading(false);
      //     return;
      //   }
      // }

      // Convert selected files to base64 if there are any new images added
      const newItemsPayload = await Promise.all(
        selectedFiles.map(async (file, index) => {
          const base64 = await convertFileToBase64(file);
          const itemIndex = items.length - selectedFiles.length + index;
          const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
          return {
            media_type: file.type,
            media_data: base64,
            media_name: fileNameWithoutExtension,
            sort_id: items[itemIndex].sort_id,
            media_title: fileNameWithoutExtension,
            media_description: items[itemIndex].media_description,
          };
        })
      );

      // console.log(newImagesPayload);

      // Create payload for existing images (i.e., those that have been edited)
      const existingItemsPayload = items
        .filter((item) => !item.preview)
        .map((item) => ({
          uuid: item.uuid,
          sort_id: item.sort_id,
          media_title: item.media_title,
          media_description: item.media_description,
        }));

      // Merge both new and existing images into the payload
      const payload = {
        items: [...newItemsPayload, ...existingItemsPayload],
      };

      // console.log(payload.images);

      // API request to update the gallery
      const response = await api.post(
        `${environment.baseUrl}/admin/gallery/create`,
        payload
      );

      if (response.data.success) {
        toast.success("Gallery images updated successfully");
        fetchAllGalleryItems();
        setSelectedFiles([]); // Clear selected files after successful upload
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setLoadingMessage("Loading images...");
    }
  };

  const fetchAllGalleryItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${environment.baseUrl}/gallery`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        const apiResponse = response.data.data.images || {};

        const extractedItems = Object.values(
          apiResponse
        ).flat() as GalleryItemProp[];

        extractedItems.forEach((item) => {
          item.media_type =
            item.media_url.endsWith(".mp4") || item.media_url.endsWith(".mov")
              ? "video"
              : "image";
        });

        extractedItems.sort((a, b) => (a.sort_id || 0) - (b.sort_id || 0));
        setItems(extractedItems);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (index: number) => {
    const itemToDelete = items[index];

    if (itemToDelete.uuid) {
      try {
        const response = await api.delete(
          `${environment.baseUrl}/admin/gallery/delete/${itemToDelete.uuid}`
        );

        if (response.data.success) {
          toast.success(response.data.message);
          setItems((prevItems) => prevItems.filter((_, i) => i !== index));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setEditingTitle(items[index].media_title);
    setEditingDescription(items[index].media_description);
  };

  const handleSaveEdit = () => {
    if (editingIndex !== null) {
      const updatedItems = [...items];
      updatedItems[editingIndex] = {
        ...updatedItems[editingIndex],
        media_title: editingTitle,
        media_description: editingDescription,
      };
      setItems(updatedItems);
      setEditingIndex(null);
      setEditingTitle("");
      setEditingDescription("");
    }
  };

  useEffect(() => {
    fetchAllGalleryItems();
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isLoading && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white flex items-center justify-center gap-3 p-6 rounded-lg ">
            {/* <h2 className="text-xl font-bold mb-4">Loading...</h2> */}
            <Spinner color="primary" size="xl" />
            <p>{loadingMessage}</p>
          </div>
        </div>
      )}
      <div className="p-4 relative min-h-screen">
        <h1 className="text-2xl font-bold mb-4">Admin Gallery</h1>

        <div className="flex justify-between items-center mb-6">
          <label className="flex items-center bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 cursor-pointer">
            <IoAddCircleOutline className="mr-2" />
            Select Media
            <input
              type="file"
              multiple
              accept="image/*,video/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>
          <button
            className="flex items-center bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300"
            onClick={fetchAllGalleryItems}
          >
            <IoRefresh className="mr-2" />
            Refresh Gallery
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-10">
          {items.map((item, index) => (
            <DraggableItem
              key={index}
              index={index}
              item={item}
              moveItem={moveItem}
              deleteItem={deleteItem}
              onEdit={handleEdit}
            />
          ))}
        </div>

        <div>
          <button
            className="flex items-center justify-center w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800"
            onClick={updateGalleryImages}
          >
            <IoCloudUploadOutline className="mr-2" />
            Upload Media
          </button>
        </div>

        {editingIndex !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">Edit Image</h2>
              <input
                type="text"
                value={editingTitle}
                onChange={(e) => setEditingTitle(e.target.value)}
                className="w-full mb-4 p-2 border rounded"
                placeholder="Image Title"
              />
              <textarea
                value={editingDescription}
                onChange={(e) => setEditingDescription(e.target.value)}
                className="w-full mb-4 p-2 border rounded"
                placeholder="Image Description"
                rows={3}
              />
              <div className="flex justify-end">
                <button
                  onClick={handleSaveEdit}
                  className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
                >
                  Save
                </button>
                <button
                  onClick={() => setEditingIndex(null)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

const DraggableItem: React.FC<DraggableItemProps> = ({
  item,
  index,
  moveItem,
  deleteItem,
  onEdit,
}) => {
  const [, ref] = useDrag({
    type: "IMAGE",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "GALLERY_ITEM",
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const cloudinaryUrl = item.media_url.includes("cloudinary.com")
    ? item.media_url.replace("/upload/", "/upload/w_900,h_700,c_fit,f_auto/")
    : item.media_url;

  return (
    <div ref={(node) => ref(drop(node))} className="relative cursor-move">
      {item.media_type === "image" ? (
        <img
          src={item.preview || cloudinaryUrl}
          alt={item.media_title}
          className="w-full h-64 object-cover rounded"
        />
      ) : (
        <video
          src={item.preview || cloudinaryUrl}
          className="w-full h-64 object-cover rounded"
          controls
        />
      )}
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
        <h3 className="font-bold">{item.media_title}</h3>
        <p className="text-sm">{item.media_description}</p>
      </div>
      <button
        className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
        onClick={() => deleteItem(index)}
      >
        <IoTrashOutline />
      </button>
      <button
        className="absolute top-2 right-12 bg-blue-500 text-white p-2 rounded-full"
        onClick={() => onEdit(index)}
      >
        <IoPencil />
      </button>
    </div>
  );
};
