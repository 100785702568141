import React from "react";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import JoinCommunityForm from "./JoinCommunityForm";

const MailchimpFormContainer = () => {
  const postUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;


  return (
    <div>
      {/* <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <JoinCommunityForm
            status={status || ""}
            message={message ? message.toString() : ""}
            onValidated={(formData: any) => subscribe(formData)}
          />
        )}
      /> */}
      <JoinCommunityForm />
    </div>
  );
};

export default MailchimpFormContainer;
