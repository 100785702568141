import React, { useEffect, useRef, useState } from "react";
import hamburger from "../../assets/icons/black-hamburger.svg";
import compassIcon from "../../assets/icons/compass.svg";
import UsersIcon from "../../assets/icons/admin-users.svg";
import OrdersIcon from "../../assets/icons/admin-orders.svg";
import SalesIcon from "../../assets/icons/admin-sales.svg";
import ActiveIcon from "../../assets/icons/admin-active-campaigns.svg";
import ConcludedIcon from "../../assets/icons/admin-concludec-campaigns.svg";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const AdminDashboard = () => {
  const data = {
    labels: [
      "20-01-23",
      "20-01-23",
      "20-01-23",
      "20-01-23",
      "20-01-23",
      "20-01-23",
      "20-01-23",
    ],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "#0FA0FC",
        borderWidth: 1,
        pointRadius: 5,
        pointBackgroundColor: "black",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {},
      y: {},
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const initialized = useRef(false);
  useEffect(() => {
    fetchDashboardIndex();
  }, []);

  const [total_orders, setTotalOrders] = useState(0);
  const [total_sales, setTotalSales] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);

  const fetchDashboardIndex = async () => {
    try {
      const response = await api.get(`${environment.baseUrl}/admin/index`);

      if (response.data.code == 200) {
        setTotalOrders(response.data.data.total_orders);
        setTotalSales(response.data.data.total_sales);
        setOrders(response.data.data.orders.rows);
      }
    } catch (error) {}
  };

  return (
    <section className="font-montserrat">
      <div className="flex items-center flex-wrap gap-6 justify-between">
        <div className="flex items-center gap-3">
          <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
          <h5 className="text-[22px] font-bold">Dashboard Overview</h5>
        </div>

        <div></div>
      </div>

      <div className="mt-7 grid md:[grid-template-columns:repeat(auto-fit,minmax(240px,1fr))] xl:grid-cols-3 gap-4 2xl:gap-10">
        <div className="rounded-[12px] bg-[#0FA0FC4D] text-[#0FA0FC] p-11  flex flex-col items-center text-center gap-2">
          <img src={OrdersIcon} alt="Orders Icon" className="w-7" />
          <p className="text-base ">Total Orders</p>
          <p className="text-2xl font-bold ">{total_orders ?? 0}</p>
        </div>
        <div className="rounded-[12px] bg-[#FFDFCB] p-11 text-[#FE6C13] flex flex-col items-center text-center gap-2">
          <img src={SalesIcon} alt="Orders Icon" className="w-7" />
          <p className="text-base ">Total Sales</p>
          <p className="text-2xl font-bold ">{total_sales ?? 0}</p>
        </div>
        <div className="rounded-[12px] bg-[#E2C3FF] p-11 text-[#8D2BE9] flex flex-col items-center text-center gap-2">
          <img src={UsersIcon} alt="Orders Icon" className="w-7" />
          <p className="text-base ">Visits</p>
          <p className="text-2xl font-bold "> 250</p>
        </div>
        <div className="rounded-[12px] bg-[#A6DBD1] p-11 text-[#008168] flex flex-col items-center text-center gap-2">
          <img src={ActiveIcon} alt="Orders Icon" className="w-7" />
          <p className="text-base ">Total active campaigns</p>
          <p className="text-2xl font-bold "> 17</p>
        </div>
        <div className="rounded-[12px] bg-[#9FADE8] p-11 text-[#10152B] flex flex-col items-center text-center gap-2">
          <img src={ConcludedIcon} alt="Orders Icon" className="w-7" />
          <p className="text-base ">Concluded campaigns</p>
          <p className="text-2xl font-bold "> 0</p>
        </div>
      </div>

      <div className="mt-10 bg-white p-8 border  border-[#DEDEDE] border-solid rounded-[18px] text-black">
        <div className="pb-3 border-b border-b-[#707070] border-solid flex items-center gap-3">
          <h5 className="text-[22px] font-bold">Trends</h5>

          <div></div>
        </div>

        <div className="my-4 flex items-center gap-5">
          <button className="outline-none">Orders</button>
          <button className="outline-none">Sales</button>
          <button className="outline-none relative after:content-[''] after:absolute after:w-5 after:bottom-[-6px] after:left-[50%] after:translate-x-[-50%] after:h-[2px] after:bg-black">
            Visits
          </button>
        </div>

        <div className="relative">
          <Line data={data} options={options} />
        </div>
      </div>

      <div className="mt-10 flex items-center gap-3">
        <img src={compassIcon} alt="campaigns-icon" className="w-[18px]" />
        <h5 className="text-[22px] font-bold">Recent Orders</h5>
      </div>
      <div className="mt-10 overflow-x-auto bg-white p-8 border border-[#DEDEDE] border-solid rounded-[18px] text-black">
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="border-b border-solid border-b-black">
              <th className="text-left font-normal pb-7">ORDER TIME</th>
              <th className="text-left pl-5 font-normal pb-7">
                DELIVERY ADDRESS
              </th>
              <th className="text-left pl-5 font-normal pb-7">PHONE</th>
              {/* <th className="text-left pl-5 font-normal pb-7">
                PAYMENT METHOD
              </th> */}
              <th className="text-left pl-5 font-normal pb-7">ORDER AMOUNT</th>
              <th className="text-left pl-5 font-normal pb-7">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order: any, index: number) => (
              <tr key={index} className="">
                <td className="pt-7">
                  {" "}
                  {moment(order.CreatedAt).format("MMM DD, YYYY")}
                </td>
                <td className="pt-7 pl-5">{order.address}</td>
                <td className="pt-7 pl-5">{order.phone}</td>
                {/* <td className="pt-7 pl-5">CASH ON DELIVERY</td> */}
                <td className="pt-7 pl-5">
                  {order.currency} {order.amount}
                </td>
                <td className="pt-7 pl-5">
                  {order.status.toLowerCase() === "pending" ? (
                    <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black text-white">
                      PENDING
                    </div>
                  ) : (
                    <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-white border-black text-black">
                      {order.status.toUpperCase()}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
