import { useEffect, useState, useRef, useCallback } from "react";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import DefaultImage from "../../assets/favicon.png";
import { IoSaveOutline, IoImageOutline } from "react-icons/io5";
import { Input } from "../../components/ui/input";
import { DndProvider, useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card, CardContent } from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { MdDragIndicator } from "react-icons/md";
import { cn } from "../../utils/function";
import { toast } from "react-toastify";

interface CampaignProps {
  sort_id: number;
  media_url: string;
  campaign_title: string;
  campaign_description: string;
  uuid: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const AdminOrderCampaigns = () => {
  const [campaigns, setCampaigns] = useState<CampaignProps[]>([]);
  const [campaignImage, setCamapaignImage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchAllCampaigns = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/admin/campaign-order`
      );
      if (response.data.success) {
        const apiResponse = response.data.data.campaigns || [];
        const campaignImages = response.data.data.campaign_images || {};

        // console.log(campaignImages);
        const highestSortId = apiResponse.reduce(
          (max: number, campaign: any) => {
            return campaign.sort_id > max ? campaign.sort_id : max;
          },
          0
        );

        let nextSortId = highestSortId + 1;

        const formattedResponse = apiResponse.map((campaign: any) => {
          if (campaign.sort_id === undefined || campaign.sort_id === 0) {
            campaign.sort_id = nextSortId++;
          }

          // Get the first image URL for the campaign
          const firstImageUrl =
            campaignImages[campaign.uuid]?.[0]?.media_url || "";

          return {
            sort_id: campaign.sort_id,
            media_url: firstImageUrl,
            campaign_title: campaign.campaign_title,
            campaign_description: campaign.campaign_description,
            uuid: campaign.uuid,
          };
        });

        // console.log(formattedResponse);
        const sortedResponse = formattedResponse.sort(
          (a: CampaignProps, b: CampaignProps) => a.sort_id - b.sort_id
        );

        setCampaigns(sortedResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveOrderedCampaign = async () => {
    const payload = {
      campaigns: campaigns.map(({ uuid, sort_id }) => ({ uuid, sort_id })),
    };

    // console.log(payload);
    try {
      const response = await api.put(
        `${environment.baseUrl}/admin/campaign-order`,
        payload
      );

      if (response.data.success) {
        // console.log(response.data);
        toast.success("Campaign order saved successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  const moveCampaign = useCallback((dragIndex: number, hoverIndex: number) => {
    setCampaigns((prevCampaigns) => {
      const updatedCampaigns = [...prevCampaigns];
      const draggedCampaign = updatedCampaigns[dragIndex];
      updatedCampaigns.splice(dragIndex, 1);
      updatedCampaigns.splice(hoverIndex, 0, draggedCampaign);
      return updatedCampaigns.map((campaign, index) => ({
        ...campaign,
        sort_id: index + 1,
      }));
    });
  }, []);

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.campaign_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-6 bg-gray-50 min-h-screen">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Order Campaigns</h1>

          <button
            onClick={saveOrderedCampaign}
            className={cn(
              "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors",
              "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
              "disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
              "bg-black text-white hover:bg-black/90",
              "h-10 py-2 px-4"
            )}
          >
            <IoSaveOutline className="w-4 h-4 mr-2" />
            Save Order
          </button>
        </div>

        <div className="mb-6">
          <Input
            type="text"
            placeholder="Search campaigns..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
        </div>

        <div className="space-y-4">
          {filteredCampaigns.map((campaign, index) => (
            <DraggableCampaignCard
              key={campaign.uuid}
              campaign={campaign}
              index={index}
              moveCampaign={moveCampaign}
            />
          ))}
        </div>
        {/* <div className="mt-8 text-center">
          <button
            className={cn(
              "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors",
              "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
              "disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
              "bg-black text-white hover:bg-black/90",
              "h-10 py-2 px-4"
            )}
          >
            <IoSaveOutline className="w-4 h-4 mr-2" />
            Save Order
          </button>
        </div> */}
      </div>
    </DndProvider>
  );
};

const DraggableCampaignCard = ({
  campaign,
  index,
  moveCampaign,
}: {
  campaign: CampaignProps;
  index: number;
  moveCampaign: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const cloudinaryImageUrl = campaign.media_url.includes("cloudinary.com")
    ? campaign.media_url.replace(
        "/upload/",
        "/upload/w_90,h_90,c_fill,ar_1:1,f_auto,q_auto:best/"
      )
    : campaign.media_url;

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | null }
  >({
    accept: "campaign",
    collect(monitor: DropTargetMonitor) {
      return {
        handlerId: monitor.getHandlerId() as string | null,
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCampaign(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "campaign",
    item: () => ({ id: campaign.uuid, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <Card ref={preview} style={{ opacity }} data-handler-id={handlerId}>
      <CardContent className="flex items-center space-x-4 p-4">
        <div ref={ref} className="cursor-move">
          <MdDragIndicator size={20} />
        </div>
        <div className="w-20 h-20 bg-gray-200 rounded-lg overflow-hidden flex-shrink-0">
          {campaign.media_url ? (
            <img
              // src={campaign.image_url}
              src={cloudinaryImageUrl}
              alt={campaign.campaign_title}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <IoImageOutline className="text-gray-400" size={32} />
            </div>
          )}
        </div>
        <div className="flex-grow">
          <h2 className="text-xl font-semibold mb-1">
            {campaign.campaign_title}
          </h2>
          <p className="text-sm text-gray-600 mb-2">
            {campaign.campaign_description}
          </p>
          {/* <Badge variant="secondary">Order: {campaign.sort_id}</Badge> */}
        </div>
      </CardContent>
    </Card>
  );
};
