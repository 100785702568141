import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/InputField";
import { BlackButton } from "../../../components/black-button";
import { RxPerson } from "react-icons/rx";
import { CiMail } from "react-icons/ci";
import { AiOutlineLock } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { Button } from "../../../components/Button";
import { environment } from "../../../environments/environment";
import api from "../../../api/api";
import { toast } from "react-toastify";
import rainbowBg from "../../../assets/images/rainbow-bg.png";

export const Signup = () => {
  const navigate = useNavigate();

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      const response = await api.post(
        `${environment.baseUrl}/auth/signup`,
        values
      );

      // Check if the login was successful
      if (response.data.success) {
        // notify success
        toast.success(response.data.message);
        localStorage.setItem("uuid_to_verify", response.data.data.uuid);
        navigate("/account/verify");
      } else {
        // Show an error message if login was not successful
        console.log(response.data.message);
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    name: Yup.string().required("Name is required"),
  });

  const googleSignIn = async () => {
    try {
      const response = await api.get("/auth/google/signin");

      if (response.data.success) {
        window.location.replace(response.data.data.redirectUrl);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <Wrapper className="web_padding_t grid gap-20 lg:grid-cols-[1fr_55%] xl:grid-cols-[1fr_45%]">
      <Formik
        initialValues={{ name: "", email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, values }) => (
          <Form className="fields w-full mx-auto web_padding_x xl:!px-[3.5rem] max-w-[550px] lg:pb-20 lg:order-2">
            <h2 className="bar_above web_heading">Create An Account</h2>
            <div className="flex flex-col items-start gap-4 mt-6 tall:mt-10 2xl:mt-10">
              <InputField
                name="name"
                label="Name"
                placeholder="Luudu"
                type="text"
                icon={<RxPerson />}
                required={true}
              />
              <InputField
                label="Email"
                name="email"
                placeholder="luudu@gmail.com"
                type="email"
                icon={<CiMail />}
                required={true}
              />
              <InputField
                name="password"
                label="Password"
                type="password"
                icon={<AiOutlineLock />}
                required={true}
              />

              <div className="mt-5 w-full mx-auto max-w-[400px] lg:w-[75%] lg:mx-[unset] grid gap-4">
                <div className="w-full flex items-center flex-wrap gap-4 justify-center">
                  <BlackButton
                    className="w-full"
                    type="submit"
                    text="Sign up"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  />
                  <Link
                    to="/login"
                    className="underline text-center underline-offset-2 hover:font-semibold"
                  >
                    Already have an account
                  </Link>
                </div>
                <p className="justify-self-center font-bold">or</p>
                <Button
                  className="w-full"
                  name="Continue with Facebook"
                  bg="#3479EA"
                  color="#fff"
                  social={<FaFacebookF style={{ color: "#3479EA" }} />}
                />
                <Button
                  className="w-full"
                  name="Continue with Google"
                  bg="#3479EA"
                  color="#fff"
                  onClick={googleSignIn}
                  social={<FcGoogle />}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div
        style={{
          backgroundImage: `url(${rainbowBg})`,
        }}
        className="card py-14 px-10 lg:mt-20 text-center"
      >
        <h3 className="web_heading">Welcome!</h3>
        <p className="max-w-[287px] mx-auto">
          Create your account to start shopping and backing campaigns.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  div.card {
    h3 {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    align-items: start;
    gap: 70px;
    margin-top: 2.5rem;

    p.self-center {
      position: relative;
      left: 30%;
      transform: translateX(-30%);
      align-self: unset;
    }
  }
`;
