export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_SIDEBAR_ADMIN = "TOGGLE_SIDEBAR_ADMIN";
export const CLOSE_SIDEBAR_ADMIN = "CLOSE_SIDEBAR_ADMIN";
export const SET_NAIRA = "SET_NAIRA";
export const SET_GHS = "SET_GHS";

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
  };
};

export const toggleSidebar_admin = () => {
  return {
    type: TOGGLE_SIDEBAR_ADMIN,
  };
};

export const closeSidebar = () => {
  return {
    type: CLOSE_SIDEBAR,
  };
};

export const closeSidebar_admin = () => {
  return {
    type: CLOSE_SIDEBAR_ADMIN,
  };
};
