import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import poweredBySttrand from "../../assets/dummy/powered-by-sttrand.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaSnapchatGhost,
  FaTwitter,
  FaTiktok,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import { ItemCountInput } from "../../components/item-count-input";
import { environment } from "../../environments/environment";
import api from "../../api/api";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { setSingleCampaign } from "../../store/authentication/actions/actions";
import { Box, Skeleton, Stack, Tab, Tabs } from "@mui/material";
import { CampaignSingle_Comments } from "./campaign-single/comments";
import { CampaignSingle_1 } from "./campaign-single/campaign";
import { CampaignSingle_Updates } from "./campaign-single/updates";
import { currencyFormat } from "../../utils/format-currency";
import moment from "moment";
import BarImg from "../../assets/images/bar.png";

interface CampaignImagesProp {
  uuid: string;
  sort_id: number;
  image_url: string;
}

export const CampaignSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isComingSoonModalVisible, setIsComingSoonModalVisible] =
    useState(false);
  const [campaignImages, setCampaignImages] = useState<CampaignImagesProp[]>(
    []
  );

  const carouselArrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    background: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleBackThisCampaign = () => {
    setIsComingSoonModalVisible(true);
  };

  const fetchCampaignDetails = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/single/${id}`
      );

      const today = moment();
      let endsIn = 0;
      // Check if the deadline has passed
      const campaign = response.data.data?.campaign;

      if (today.isAfter(campaign.campaign_deadline)) {
        endsIn = 0;
      } else {
        // Calculate the number of days ahead
        endsIn = Math.abs(today.diff(campaign.campaign_deadline, "days"));
      }

      const apiResponseImages = response.data.data.campaign_images;
      const extractedImages = apiResponseImages.map((item: any) => {
        return {
          image_url: item.image_url,
          sort_id: item.sort_id,
          uuid: item.uuid,
        };
      });

      // console.log(extractedImages);

      const sortedImages = extractedImages.sort(
        (a: CampaignImagesProp, b: CampaignImagesProp) => a.sort_id - b.sort_id
      );

      // console.log(sortedImages);

      setCampaignImages(sortedImages);

      setCampaignDetails({ ...response.data.data?.campaign, endsIn });
      formatSizeOptions({ ...response.data.data?.campaign, endsIn });
      setCampaignBacked(response.data.data?.campaign_backed ?? false);
      setCurrent_orders(response.data.data?.current_orders ?? 0);
      dispatch(setSingleCampaign(response.data.data?.campaign));
    } catch (error) {
      navigate("/campaigns");
    }
  };

  const [activeTab, setActiveTab] = React.useState(0);

  const formatSizeOptions = (campaign: ICampaignAdmin) => {
    const options: any[] = [];

    if (campaign.size_men_shoe) {
      options.push({
        label: "Men Shoe",
        options: campaign.size_men_shoe
          .split(",")
          .map((x: any) => ({ value: x, label: x })),
      });
    }

    if (campaign.size_women_shoe) {
      options.push({
        label: "Women Shoe",
        options: campaign.size_women_shoe
          .split(",")
          .map((x: any) => ({ value: x, label: x })),
      });
    }

    setSizes(options);
  };

  useEffect(() => {
    fetchCampaignDetails();
  }, []);

  // State to manage the value
  const [itemCount, setItemCount] = useState(1);

  // Function to handle value change in the parent component
  const handleItemCountChange = (newValue: number) => {
    setItemCount(newValue);
  };

  const [campaignDetails, setCampaignDetails] = useState<ICampaignAdmin | null>(
    null
  );
  const [campaignBacked, setCampaignBacked] = useState<boolean>(false);

  const [current_orders, setCurrent_orders] = useState<number>(0);

  const [currentCarouselSlide, setCarouselSlide] = useState(0);

  const [sizes, setSizes] = useState<any[]>([]);
  const [selectedSize, setSelectedSize] = useState<any>(null);

  const currencyView = "$";

  const formatBarWidth = () => {
    const valueCalculated =
      (current_orders * 100) / (campaignDetails?.end_goal_order_number ?? 0);
    if (valueCalculated < 50) {
      return valueCalculated + "%";
    } else if (valueCalculated <= 100) {
      return `calc(${valueCalculated}%)`;
    } else {
      return `100%`;
    }
  };

  // the carousel for campaign images
  const repeatedRef = campaignDetails && (
    <div className="relative bg-white campaign-carousel">
      <Carousel
        autoPlay={false}
        infiniteLoop={true}
        interval={6000}
        showIndicators={true}
        showStatus={false}
        showThumbs={false}
        // showArrows={true}
        selectedItem={currentCarouselSlide}
        onChange={(index) => setCarouselSlide(index)}
        className="campaign_single_carousel"
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...carouselArrowStyles, left: 15 }}
            >
              <FaChevronLeft />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...carouselArrowStyles, right: 15 }}
            >
              <FaChevronRight />
            </button>
          )
        }
      >
        {/* {campaignDetails &&
          campaignDetails.image_url
            ?.split(",")
            .map((img) => (
              <img
                key={img}
                src={img}
                className="object-cover hover:scale-[1.2] [aspect-ratio:684/450]"
              />
            ))} */}
        {campaignDetails &&
          campaignImages.map((img) => {
            const cloudinaryImageUrl = img.image_url.includes("cloudinary.com")
              ? img.image_url.replace(
                  "/upload/",
                  "/upload/w_1920,h_1080,c_fit,ar_16:9,f_auto,q_auto:best,dpr_auto,c_lpad,b_auto:predominant/"
                )
              : img.image_url;

            return (
              <img
                key={img.uuid}
                src={cloudinaryImageUrl}
                className="object-cover hover:scale-[1.2] [aspect-ratio:684/450]"
              />
            );
          })}
      </Carousel>

      <div className="flex justify-between items-center mt-4 tall:mt-8 2xl:mt-8">
        <div className="flex gap-2 items-center">
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaFacebookF className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaInstagram className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaSnapchatGhost className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaTwitter className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaTiktok className="text-white text-base" />
          </div>
        </div>
      </div>
    </div>
  );

  return campaignDetails ? (
    <div className="lg:py-8 py-4 web_padding_x grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
      <div className="lg:order-1">
        <h2 className="web_heading_2 bar_above">
          {campaignDetails?.campaign_title}
        </h2>
        <h2 className="web_heading_2 mt-2">{campaignDetails?.product_title}</h2>

        <div className="mt-5 lg:hidden">{repeatedRef}</div>

        <div className="h-[250px] flex items-center justify-center px-5">
          <div className="relative p-[4px] flex-1 border-2 border-solid  h-10 rounded-[21px]">
            <div
              style={{
                width: formatBarWidth(),
              }}
              className="relative h-full rounded-[21px] overflow-hidden"
            >
              <img src={BarImg} className="object-cover h-full w-full" />
              <div className="slanted_bars white_slanted_bars h-full w-full top-0 left-0 absolute bg-transparent"></div>
            </div>
            <div className="absolute w-full left-0 h-full top-0">
              <div
                className="campaign_dotted_line_with_circles"
                style={{
                  left: `${
                    ((campaignDetails?.production_goal_order_number ?? 0) *
                      100) /
                    (campaignDetails?.end_goal_order_number ?? 0)
                  }%`,
                }}
              >
                <span className="dot_above">
                  Production <br /> Goal <br />{" "}
                  {campaignDetails?.production_goal_order_number ?? 0} orders
                </span>
                <span className="dot_below">
                  {currencyView}{" "}
                  {currencyFormat(campaignDetails?.production_goal_price) ?? 0}{" "}
                  <br /> P.G Price
                </span>
              </div>

              <div className="campaign_dotted_line_with_circles right-0">
                <span className="dot_above">
                  End <br /> Goal <br />{" "}
                  {campaignDetails?.end_goal_order_number ?? 0} orders
                </span>
                <span className="dot_below">
                  {currencyView}{" "}
                  {currencyFormat(campaignDetails?.end_goal_price) ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between font-bold gap-2">
          <div>
            <div className="text-base md:text-[24px]">
              {currencyView}{" "}
              {currencyFormat(campaignDetails?.current_price) ?? 0}
            </div>
            <div className="text-xs md:text-sm">Current Price</div>
          </div>

          <div className="bg-black w-[2px] h-[50px]"></div>

          <div>
            <div className="text-base md:text-[24px]">{current_orders}</div>
            <div className="text-xs md:text-sm">Current Orders</div>
          </div>

          <div className="bg-black w-[2px] h-[50px]"></div>

          <div>
            <div className="text-base md:text-[24px]">
              {campaignDetails?.endsIn}
            </div>
            <div className="text-xs md:text-sm">Days To Go</div>
          </div>
        </div>
        {/* grid-cols-[auto_1fr] */}
        <form className="mt-10 flex flex-col tall:mt-14 2xl:mt-14   gap-4 sm:gap-6 lg:gap-7 ">
          <div className="flex items-center gap-9">
            <div>
              <div className="sm:text-lg font-bold">Size</div>
              <div className="text-xs sm:text-sm">(Size Chart)</div>
            </div>
            <Select
              className="flex-1"
              classNamePrefix="custom_select"
              options={sizes}
              onChange={(e) => {
                setSelectedSize(e.value);
              }}
              placeholder="Select size"
            />
          </div>

          <div className="sm:text-lg font-bold flex items-center gap-5">
            <p>Quantity</p>
            <div className="w-[120px]">
              <ItemCountInput
                value={itemCount}
                onValueChange={handleItemCountChange}
              />
            </div>
          </div>

          <div className="col-span-2">
            <button
              type="button"
              onClick={() => {
                setIsComingSoonModalVisible(true);
                // sessionStorage.setItem("campaignToBack", id!);
                // sessionStorage.setItem("quantity", itemCount.toString());
                // sessionStorage.setItem("size", selectedSize.toString());
                // sessionStorage.setItem(
                //   "amount",
                //   campaignDetails!.current_price.toString()
                // );
                // navigate(
                //   `/back-campaign/${id}?quantity=${itemCount}&amount=${campaignDetails!.current_price.toString()}&size=${selectedSize}`
                // );
              }}
              className="px-6 py-2 rounded-[24px] block w-[80%] max-w-[450px] mx-auto text-white bg-[#028858] hover:bg-[#015738]"
            >
              Back this Campaign
            </button>
            {isComingSoonModalVisible && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 flex  flex-col rounded-lg shadow-lg">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    Coming Soon
                  </h2>
                  <p>This feature is coming soon. Stay tuned!</p>
                  <button
                    onClick={() => setIsComingSoonModalVisible(false)}
                    className="mt-4 px-4 py-2 bg-[#10152B] text-white rounded-full"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            {/* <img src={poweredBySttrand} className="block mx-auto mt-3" alt="" /> */}

            <div className="mt-4 text-center">
              This product will go into production at the end of the campaign at
              least if the Production Goal is met by{" "}
              {moment(campaignDetails?.campaign_deadline).format(
                "Do, MMMM YYYY"
              )}{" "}
              at 11:59pm EST.
            </div>
          </div>
        </form>
      </div>

      <div>
        <div className="hidden lg:block">{repeatedRef}</div>

        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
        >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Comments" />
            <Tab label="Campaign" />
            <Tab label="Updates" />
          </Tabs>
        </Box>
        <div className="mt-5">
          {activeTab === 0 && (
            <CampaignSingle_Comments campaignBacked={campaignBacked} />
          )}
          {activeTab === 1 && <CampaignSingle_1 />}
          {activeTab === 2 && <CampaignSingle_Updates />}
        </div>
      </div>
    </div>
  ) : (
    <div className="web_padding_y web_padding_x">
      <Stack spacing={1}>
        <Stack spacing={1} direction={"row"}>
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
        </Stack>
        <Stack spacing={1} direction={"row"}>
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
        </Stack>
      </Stack>
    </div>
  );
};
