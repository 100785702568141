import React, { useEffect, useState } from "react";
import { FiX, FiAlertCircle, FiMail, FiUser } from "react-icons/fi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

interface JoinCommunityFormProps {
  first_name: string;
  last_name: string;
  email: string;
}

interface JoinCommunityPopUpFormProps {
  initialValues: JoinCommunityFormProps;
  isVisible: (value: boolean) => void;
}

const JoinCommunityPopUpForm: React.FC<JoinCommunityPopUpFormProps> = ({
  initialValues,
  isVisible,
}) => {
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const mailchimpUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
  });

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const postUrl = `${mailchimpUrl}&EMAIL=${encodeURIComponent(email)}&MERGE1=${encodeURIComponent(firstName)}&MERGE2=${encodeURIComponent(lastName)}`;
    // console.log(postUrl);

    fetch(postUrl, {
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => {
        // console.log(response);
        if (response.statusText === "") {
          setStatus("success");
          setMessage("You have successfully subscribed!");
        } else {
          setStatus("error");
          setMessage("An error occurred, please try again later.");
        }
      })
      .catch(() => {
        setStatus("error");
        setMessage("An error occurred, please try again later.");
      });
  };

  useEffect(() => {
    if (status === "success") {
      const timer = setTimeout(() => {
        isVisible(false);
      }, 5000); // Close popup after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <div>
      <form onSubmit={handleSubscribe} className="space-y-4">
        <div className="relative">
          <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="First Name"
          />
        </div>

        <div className="relative">
          <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="Last Name"
          />
        </div>

        <div className="relative">
          <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="Email"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
        >
          Subscribe
        </button>
        {status && (
          <div
            className={`${
              status === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            } p-3 rounded-lg mb-4`}
          >
            {message}
          </div>
        )}
      </form>
      {/* <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          handleSubscribe(values);
          // setSubmitting(false);
        }}
      >
        <Form className="space-y-4">
          <div className="relative">
            <Field
              type="text"
              id="first_name"
              name="first_name"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="First Name"
            />
            <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="first_name">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="relative">
            <Field
              type="text"
              id="last_name"
              name="last_name"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="Last Name"
            />
            <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="last_name">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="relative">
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
              placeholder="Email"
            />
            <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <ErrorMessage name="email">
              {(msg) => (
                <div className="text-red-600 text-sm mt-1 flex items-center">
                  <FiAlertCircle size={16} className="mr-1" />
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
          <button
            type="submit"
            className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            Subscribe
          </button>
          {status && (
            <div
              className={`${
                status === "success"
                  ? "bg-green-100 text-green-700"
                  : "bg-red-100 text-red-700"
              } p-3 rounded-lg mb-4`}
            >
              {message}
            </div>
          )}
        </Form>
      </Formik> */}
    </div>
  );
};

const MailchimpPopUp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const initialValues: JoinCommunityFormProps = {
    email: "",
    first_name: "",
    last_name: "",
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3500); // Show popup after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-8 w-screen !overflow-x-hidden !overflow-y-hidden">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-md w-full relative sm:mx-auto mx-4 sm:my-auto -mt-[20vh]">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FiX size={24} />
        </button>

        <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#10152B] mb-4">
          JOIN OUR COMMUNITY
        </h2>
        <p className="text-gray-600 mb-6 text-center">
          Sign up to be the first to hear about our limited-edition collection
          and exclusive products when they go live in December.
        </p>

        <JoinCommunityPopUpForm
          isVisible={setIsVisible}
          initialValues={initialValues}
        />
      </div>
    </div>
  );
};

export default MailchimpPopUp;
