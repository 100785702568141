import { useSelector } from "react-redux";
import howItWorksImg from "../../assets/maskedb.jpg";
import { IAppState } from "../../store/store";
import { HowItWorks2 } from "./HowItWorks2";
import { useState } from "react";

export const HowItWorks = () => {
  return (
    <>
      {/* <div className="flex items-center justify-center gap-4 mb-5 mt-10"></div> */}
      <HowItWorks2 />
    </>
  );
};
