import { useState } from "react";
import GhanaFlag from "../assets/ghana-flag.png";
import NigeriaFlag from "../assets/nigeria-flag.png";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { FaChevronDown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../store/store";
import { FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { logout } from "../store/authentication/actions/actions";

export const NavbarUserIcon = () => {
  const token = useSelector((state: IAppState) => state.auth.token);
  const dispatch = useDispatch();

  return token ? (
    <Menu
      portal
      className="account_logout_menu"
      direction="bottom"
      align="center"
      offsetY={16}
      menuButton={
        <MenuButton className="rounded-full shadow-md w-8 h-8 flex justify-center items-center">
          <FiUser size={18} />
        </MenuButton>
      }
    >
      <MenuItem>
        <NavLink to="/account/profile">
          <div>Account</div>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={() => dispatch(logout())}>
        <div>Logout</div>
      </MenuItem>
    </Menu>
  ) : null;
};
